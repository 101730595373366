<template>
    <div>
        <PageHeader :title="pageHeader">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <BackButton :to="{ name: 'jobs' }" />
            <BaseButton v-show="isEdit" skin="primary" @click="scrollToCandidates">Ver Candidatos</BaseButton>
        </ActionArea>

        <div class="flex justify-center">
            <Loading text="Buscando informações... ..." :show="loading" />
        </div>

        <div class="grid grid-cols-1 gap-x-2" v-if="!loading">
            <div class="col-span-2">
                <form @submit.prevent="submit" novalidate>
                    <BaseFormContainer :title="formTitle" :subtitle="`Número de candidatos: ${candidates.length}`">
                        <template v-slot:form>
                                <BaseFormSectionHeader title="Sobre a vaga" subtitle="Informações Básicas"></BaseFormSectionHeader>
                                <div class="">
                                    <BaseSlider v-if="isEdit && job.id" v-model="job.status" :label="job.status ? 'Aberto' : 'Finalizado'" />
                                </div>
                                <div class="grid grid-cols-2 form-row">
                                    <div>
                                        <BaseSelect :is-invalid="v$.company.$error" @blur="v$.company.$touch" :disabled="job.company && isEdit" label="Empresa / Cliente" :options="companyOptions" v-model="job.company" :required="true" />
                                        <div v-for="error in v$.$errors" :key="error.$uid">
                                            <BaseErrorMessage v-if="error.$property === 'company'" :message="error.$message" />
                                        </div>
                                    </div>
                                    <div>
                                        <BaseInput :is-invalid="v$.role.$error" label="Função" @blur="v$.role.$touch" placeholder="Engenheiro de Software" v-model="job.role" :required="true" />
                                        <div v-for="error in v$.$errors" :key="error.$uid">
                                            <BaseErrorMessage v-if="error.$property === 'role'" :message="error.$message" />
                                        </div>
                                    </div>
                                </div>
                                <div class="grid grid-cols-4 form-row">
                                    <div>
                                        <BaseSelect :options="deadlineOptions" :is-invalid="v$.deadline.$error" label="Prazo para contração" @blur="v$.deadline.$touch" v-model="job.deadline" :required="true" />
                                        <div v-for="error in v$.$errors" :key="error.$uid">
                                            <BaseErrorMessage v-if="error.$property === 'deadline'" :message="error.$message" />
                                        </div>
                                    </div>
                                    <div>
                                        <BaseSelect :options="contractType" label="Regime de contratação" v-model="job.contract_type" />
                                    </div>
                                    <div>
                                        <BaseInputMoney label="Salário" v-model="job.salary" />
                                    </div>
                                    <div>
                                        <label class="label">Quant. Vagas</label>
                                        <input type="number" v-model="job.amount" />
                                    </div>
                                </div>
                                <div class="grid grid-cols-2 form-row">
                                    <div>
                                        <BaseInput label="Horário de trabalho" v-model="job.work_hours" />
                                    </div>
                                    <div>
                                        <BaseInput label="Local de trabalho" v-model="job.work_location" />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 form-row">
                                    <div>
                                        <BaseTextarea rows="5" label="Benefícios" v-model="job.benefits" :maxlength="20000" />
                                    </div>
                                </div>

                                <BaseFormSectionHeader title="Informações em relação ao candidato"></BaseFormSectionHeader>
                                <div class="grid grid-cols-1 form-row">
                                    <div>
                                        <BaseTextarea rows="7" label="Requisitos necessários (experiência, formação, conhecimentos, habilidades)" v-model="job.requirements" :maxlength="20000" />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 form-row">
                                    <div>
                                        <BaseTextarea rows="7" label="Atividades a serem desenvolvidas" v-model="job.activities" :maxlength="20000" />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 form-row">
                                    <div>
                                        <BaseTextarea rows="7" label="Informações Adicionais (comportamentos desejados, sexo, idade, particularidades em geral)" v-model="job.obs" :maxlength="20000" />
                                    </div>
                                </div>
                                <FileUploader class="mt-4" text="Adicionar Arquivos" :multiple="true" :showProgress="true" accept="*" @onUpload="onUpload" />
                                <FileTable class="mt-2" :loading="loadingFiles" :files="job.files" @removeFile="removeFile" />
                        </template>
                        <template v-slot:actions>
                            <BaseButton skin="primary" :is-submit="true">{{ isEdit ? 'Atualizar': 'Salvar' }}</BaseButton>
                        </template>
                    </BaseFormContainer>
                </form>
            </div>
        </div>


        <ActionArea class="mt-4" v-if="!loading && job.id">
            <BaseButton :to="{ name: 'candidate', params: { job_id: job.id } }" skin="primary">
                <template v-slot:icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                </template>
                Adicionar
            </BaseButton>
        </ActionArea>
        
        <!-- Scroll to -->
        <div id="candidatesList"></div>

        <div class="grid grid-cols-5 mb-6 gap-x-4 card p-12" v-if="job.id && !loading">
            <div>
                <BaseSelect :options="statusOptions" empty-option="Todos" label="Filtrar por status" v-model="candidateFilter.status" @change="loadCandidates" />
            </div>
            <div>
                <BaseInput label="Filtrar por nome" v-model="candidateFilter.name" v-on:keyup.enter="loadCandidates" />
            </div>
            <div class="flex gap-x-4 mt-8" v-if="loadingCandidates">
                <span class="text-green-500">Filtrando...</span>
                <svg class="animate-spin h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
        </div>

        <Alert class="mt-4" text="Ainda não existem candidatos cadastrados para essa vaga" :show="isEdit && !candidates.length && !loading" />

        <div class="table-container" v-show="job.id && candidates.length && !loadingCandidates && !loading">
            <div class="mt-4">
                <table class="min-w-full">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nome</th>
                            <th>Telefone</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Currículo</th>
                            <th>Arquivo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="table-row" v-for="candidate in candidates" :key="candidate.id">
                            <td>
                                <BaseButton skin="primary-outline" :to="{ name: 'candidate', params: { job_id: job.id, id: candidate.id }}">Gerenciar</BaseButton>
                            </td>
                            <td>
                                <span class="text-sm leading-5 text-green-700">{{ candidate.name }}</span>
                            </td>
                            <td>
                                <span class="text-sm leading-5 text-green-700">{{ candidate.phone }}</span>
                            </td>
                            <td class="flex flex-col">
                                <span class="text-sm leading-5 text-green-700">{{ candidate.email }}</span>
                                <span class="text-sm leading-5 text-green-700">{{ candidate.email2 }}</span>
                            </td>
                            <td>
                                <Tag v-if="candidate.status === 'list'" type="warning" text="na lista" />
                                <Tag v-if="candidate.status === 'interview_available'" type="info" text="apto para entrevista" />
                                <Tag v-if="candidate.status === 'interview_done'" type="alternative" text="entrevista realizada" />
                                <Tag v-if="candidate.status === 'approved'" type="success" text="selecionado" />
                                <Tag v-if="candidate.status === 'disapproved'" type="danger" text="reprovado" />
                            </td>
                            <td>
                                <BaseButton skin="primary-outline-small" v-if="candidate.resume_url" :external="true" :href="candidate.resume_url">Currículo</BaseButton>
                            </td>
                            <td>
                                <BaseButton v-if="candidate.attachment_url" skin="primary-outline-small" :external="true" :href="candidate.attachment_url">Arquivo</BaseButton>
                            </td>
                            <td v-if="authInfo.has_delete_candidate">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-red-600 w-6 h-6 cursor-pointer" @click="deleteCandidate(candidate.id)">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Pagination :total="totalItens" :limit="limit" @changePage="changePagination" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import BackButton from '@/components/BackButton';
import { useRoute, useRouter } from 'vue-router';
import MaskService from '../services/MaskService';
import { useVuelidate } from '@vuelidate/core'
import FileUploader from '@/components/FileUploader';
import Pagination from '@/components/Pagination';
import FileTable from '@/components/FileTable';
import Alert from '@/components/Alert';
import Tag from '@/components/Tag';
import Swal from 'sweetalert';
import { CompanyService, JobService, CandidateService } from '../services/Entity';
import { requiredMessage } from '../services/VuelidateMessageService';
import UtilService from '../services/UtilService';
import AuthService from '../services/AuthService';

export default {
    name: 'Profile',
    components: {
        BackButton,
        Pagination,
        FileUploader,
        FileTable,
        Alert,
        Tag
    },
    async setup() {
        const authInfo = AuthService.getAuthInfo();
        const pageHeader = ref('Novo Processo Seletivo');
        const formTitle = ref('Abrir novo processo seletivo');
        const route = useRoute();
        const router = useRouter();
        const isEdit = ref(false);
        const loading = ref(false);
        const loadingCandidates = ref(false);
        const loadingFiles = ref(false);
        const upload = ref();
        const candidates = ref([]);
        const page = ref(1);
        const limit = ref(20);
        const totalItens = ref(0);
        const candidateFilter = ref({});
        const filter = ref({});
        const job = ref({
            salary: 0,
            contract_type: 'clt',
            work_hours: '',
            status: true,
            amount: 1,
            files: []
        });
        const companyOptions = ref([]);
        const rules = computed(() => ({
            role: { required: requiredMessage },
            company: { required: requiredMessage },
            deadline: { required: requiredMessage },
        }));
        const statusOptions = ref([
            { title: 'Na lista', value: 'list' },
            { title: 'Apto para entrevista', value: 'interview_available' },
            { title: 'Entrevista Concluida', value: 'interview_done' },
            { title: 'Aprovado', value: 'approved' },
            { title: 'Reprovado', value: 'disapproved' },
        ]);
        const deadlineOptions = [
            { title: '10 dias - Urgente', value: 10 },
            { title: '20 dias - Programado', value: 20 },
        ];
        const contractType = [
            { title: 'CLT', value: 'clt' },
            { title: 'PJ', value: 'pj' },
            { title: 'Híbrido', value: 'hybrid' }
        ];

        async function init() {
            loading.value = true;
            await loadData();
            loading.value = false;
        }

        async function loadData() {
            const jobId = handleRoute(route);
            await loadCompanies();

            if (!jobId) {
                return;
            }
            
            filter.value.job = jobId;
            isEdit.value = true;
            await getJobById(jobId);

            candidateFilter.value.job = job.value.id;
            await loadCandidates();
            formTitle.value = `${job.value.role}`;
            pageHeader.value = 'Processo Seletivo';
        }

        function handleRoute(route) {
            const jobId = route.params.id;

            if (!jobId) {
                return;
            }

            return jobId ? jobId : false;
        }

        async function submit() {
            const isValid = await v$.value.$validate();
            if (!isValid) {
                Swal({
                    title: 'Preencha todos os campos obrigatórios',
                    icon: 'warning'
                });
                return;
            }

            if (!isEdit.value) {
                await save();
            } else {
                await update();
            }
        }

        async function save() {
            const jobObject = { ...job.value };
            
            try {
                loading.value = true;
                const { data } = await JobService.create(jobObject);
                router.push({ name: 'job', params: { id: data.id } });

                Swal({
                    title: 'Vaga Salva',
                    icon: 'success',
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao salvar a vaga',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function update() {
            const jobObject = { ...job.value };
            
            try {
                loading.value = true;
                const { data } = await JobService.update(jobObject);
                await getJobById(data.id);
                loading.value = false;

                Swal({
                    title: 'Vaga Atualizada!',
                    icon: 'success'
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar essa vaga',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function getJobById(jobId) {
            try {
                const { data } = await JobService.getById(jobId);

                job.value = data;
                job.value.company = job.value.company.id;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter os dados dessa vaga',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadCompanies() {
            try {
                const filter = { page: 1, limit: 99999 };
                const { data: { total, items } } = await CompanyService.list(filter.page, filter.limit);

                companyOptions.value = items.map(company => {
                    return {
                        title: company.name,
                        value: company.id
                    }
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos clientes',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadCandidates() {
            try {
                loadingCandidates.value = true;
                const listingFilter = { page: page.value, limit: limit.value };
                const { data: { total, items } } = await CandidateService.list(listingFilter.page, listingFilter.limit, candidateFilter.value);

                totalItens.value = total;
                candidates.value = items;
                loadingCandidates.value = false;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos clientes',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function changePagination(currentPage) {
            page.value = currentPage;
            loading.value = true;
            await loadCandidates();
            loading.value = false;
        }

        function onUpload(data) {
            const { success } = data;
            const file = success[0];

            job.value.files.push(file);
        }

        async function removeFile(fileId) {
            try {
                Swal({
                title: 'Deseja realmente removero arquivo?',
                text: 'Não será possível restaurar o mesmo',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Deletar',
                        className: 'swal-button--danger'
                    }
                }}).then(async (confirm) => {
                    if (!confirm) return;

                    const requestData = { job: job.value.id, file: fileId };
                    loadingFiles.value = true;
                    job.value.files.forEach((file, index) => {
                        if (file.id == fileId) {
                            job.value.files.splice(index, 1);
                        }
                    });

                    if (!isEdit.value) {
                        loadingFiles.value = false;
                        return;
                    }

                    await JobService.removeFile(requestData.job, requestData.file, requestData);
                    loadingFiles.value = false;
                });
            } catch(err) {
                loadingFiles.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao excluir o arquivo',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        function scrollToCandidates() {
            UtilService.scrollTo(document.getElementById('candidatesList'), window);
        }

        async function deleteCandidate(candidateId) {
            Swal({
                title: 'Tem certeza que deseja excluir esse candidato?',
                text: 'Após remover não é possível recupera-lo',
                icon: 'warning',
                buttons: {
                    cancel: 'Voltar',
                    confirm: {
                        text: 'Excluir',
                        className: 'swal-button--danger'
                    }
                },
                dangerMode: true
            }).then(async (confirm) => {
                if (confirm) {
                    await CandidateService.destroy(candidateId);
                    await loadCandidates();
                }
            });
        }

        watch(() => route.params, async (to, from) => {
            if (route.name == 'job' && (from != to)) {
                await init();
            }
        }, { deep: true });

        init();
        const v$ = useVuelidate(rules, job);

        return {
            authInfo,
            deleteCandidate,
            removeFile,
            pageHeader,
            loadingCandidates,
            scrollToCandidates,
            candidateFilter,
            statusOptions,
            filter,
            loadCandidates,
            changePagination,
            loadingFiles,
            formTitle,
            MaskService,
            isEdit,
            candidates,
            job,
            v$,
            submit,
            save,
            update,
            onUpload,
            loading,
            upload,
            deadlineOptions,
            contractType,
            companyOptions,
            page,
            limit,
            totalItens
        };
    }
}
</script>

