
import HttpClient from '../HttpService';

function countData(filter) {
    return HttpClient.post(`/analytics/list`, filter);
}

function requestsPerAdmin(filter) {
    return HttpClient.post(`/analytics/requests/admins`, filter);
}

export default {
    countData,
    requestsPerAdmin
}
