<template>
    <div>
        <PageHeader :title="pageHeader">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="page-header-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea v-if="!loadingEntireScreen">
            <BaseButton :to="{ name: 'company', params: {} }" skin="primary">
                <template v-slot:icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                </template>
                Adicionar
            </BaseButton>
        </ActionArea>

        <div class="grid cols-1 mb-4" v-if="!loadingEntireScreen">
            <BaseFormContainer title="Filtrar por:">
                    <template v-slot:form>
                        <form v-on:submit.prevent>
                            <div class="grid grid-cols-4 gap-x-8">
                                <div class="flex gap-x-4">
                                    <BaseInput label="Nome" v-model="filter.name" v-on:keyup.enter="loadData" />
                                    <BaseButton skin="primary" class="mt-6" @click="loadData">Filtrar</BaseButton>
                                </div>
                            </div>
                        </form>
                    </template>
            </BaseFormContainer>
        </div>

        <div class="flex justify-center pb-4">
            <Loading :show="loading" text="Carregando dados, aguarde..." />
        </div>

        <Alert text="Ainda não existem clientes cadastrados" :show="!companies.length && !loading" />

        <div class="table-container" v-if="companies.length && !loadingEntireScreen">
            <table class="min-w-full">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Whatsapp</th>
                        <th>Estado - Cidade</th>
                        <th>CNPJ/CPF</th>
                        <th v-if="authInfo.has_delete"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" v-for="(company, index) in companies" :key="company.id">
                        <td>
                            <div class="flex items-center">
                                <div>
                                    <div class="text-sm leading-5 text-gray-800">#{{ index + 1 }}</div>
                                </div>
                            </div>
                        </td>
                        <td class="text-left text-sm leading-5 flex gap-x-8">
                            <BaseButton skin="primary-outline" :to="{ name: 'company', params: { id: company.id } }">Gerenciar</BaseButton>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-700 float-left">{{ company.name }}</span>
                            <a v-if="company.file_url" class="ml-2 -mt-2 bg-green-100 hover:bg-green-200 float-left p-1 rounded-md" target="_blank" :href="company.file_url">
                                <svg xmlns="http://www.w3.org/2000/svg" v-if="company.file_url" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                </svg>
                            </a>
                        </td>
                        <td class="flex flex-col">
                            <span class="text-sm leading-5 text-green-700">{{ company.email }}</span>
                            <span class="text-sm leading-5 text-green-700">{{ company.email2 }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-700">{{ company.whatsapp }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-700">{{ company.state.name }} - {{ company.city.name }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-700">{{ company.cnpj || company.cpf }}</span>
                        </td>
                        <td v-if="authInfo.has_delete">
                            <BaseButton skin="danger-small" @click="deleteCompany(company.id)">Excluir</BaseButton>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination :total="totalItens" :limit="limit" @changePage="changePagination" />
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';
import { CompanyService } from '../services/Entity';
import Alert from '@/components/Alert';
import Pagination from '@/components/Pagination';
import Swal from 'sweetalert';
import { defaultDate } from '../filters/date';
import AuthService from '../services/AuthService';

export default {
    components: {
        Pagination,
        Alert
    },
    setup() {
        const pageHeader = ref('Clientes / Empresas');
        const filter = ref({});
        const page = ref(1);
        const limit = ref(50);
        const totalItens = ref(0);
        const loading = ref(false);
        const loadingEntireScreen = ref(false);
        const companies = ref([]);
        const authInfo = AuthService.getAuthInfo();

        async function init() {
            loadingEntireScreen.value = true;
            await loadData()
            loadingEntireScreen.value = false;
        }

        async function loadData() {
            loading.value = true;
            await loadCompanies(filter.value);
            loading.value = false;
        }

        async function loadCompanies(filter) {
            try {
                const listingFilter = { page: 1, limit: 9999 };
                const { data: { total, items } } = await CompanyService.list(listingFilter.page, listingFilter.limit, filter);

                companies.value = items;
                totalItens.value = total;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos clientes',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function changePagination(currentPage) {
            page.value = currentPage;
            loading.value = true;
            await loadCompanies(page.value, limit.value, filter.value);
            loading.value = false;
        }

        async function deleteCompany(companyId) {
            Swal({
                title: 'ATENÇÃO! Deseja mesmo excluir uma EMPRESA?',
                text: 'Será excluido juntamente com a empresa, todos os seus registros, reuniões, pedidos, usuários, arquivos e etc.',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Excluir Agora',
                        className: 'swal-button--danger'
                    }
                },
                dangerMode: true
            }).then(async (confirm) => {
                if (confirm) {
                    loading.value = true;
                    const { data: { data } } = await CompanyService.destroy(companyId);
                    await loadData();


                    Swal({
                        title: `Excluida`,
                        icon: 'success',
                    });
                }
            });
        }

        init();

        return {
            deleteCompany,
            authInfo,
            loadData,
            pageHeader,
            companies,
            changePagination,
            loading,
            loadingEntireScreen,
            page,
            limit,
            totalItens,
            filter,
            defaultDate
        }
    },
}
</script>