<template>
    <div>
        <PageHeader :title="pageHeader">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <BackButton :to="{ name: 'users' }" />
        </ActionArea>

        <div class="flex justify-center mb-6" v-if="loading">
            <Loading text="Buscando Dados... Aguarde..." :show="loading" />
        </div>

        <div class="grid grid-cols-1 gap-x-2" v-if="!loading">
            <div>
                <form @submit.prevent="submit" novalidate>
                    <BaseFormContainer :title="formTitle">
                        <template v-slot:form>
                                <BaseFormSectionHeader title="Sua foto" subtitle="Escolha uma foto para o seu perfil"></BaseFormSectionHeader>

                                <div class="p-8 flex">
                                    <ThumbRounded class="mr-12" size="large" :path="userAdmin.photo_url" />
                                    <FileUploader class="mt-4" text="Enviar Foto" :showProgress="true" :accept="acceptMimeTypeImage" @onUpload="onUpload" />
                                </div> 

                                <BaseFormSectionHeader title="Informações Pessoais" subtitle="Informações Básicas"></BaseFormSectionHeader>
                                <div class="grid grid-cols-3 form-row">
                                    <div>
                                        <BaseInput :is-invalid="v$.name.$error" label="Nome e Sobrenome" @blur="v$.name.$touch" placeholder="Leonardo Da Vinci" v-model="userAdmin.name" :required="true" />
                                        <div v-for="error in v$.$errors" :key="error.$uid">
                                            <BaseErrorMessage v-if="error.$property === 'name'" :message="error.$message" />
                                        </div>
                                    </div>
                                    <div>
                                        <BaseInput :disabled="!isCurrentUserAdmin" :is-invalid="v$.email.$error" label="Email" @blur="v$.email.$touch" placeholder="leonardo@davinci.com" v-model="userAdmin.email" :required="true" />
                                        <div v-for="error in v$.$errors" :key="error.$uid">
                                            <BaseErrorMessage v-if="error.$property === 'email'" :message="error.$message" />
                                        </div>
                                    </div>
                                    <div>
                                        <BaseInput label="Telefone" placeholder="(11) 99999-9999" v-model="userAdmin.phone" v-maska="MaskService['phone']"/>
                                    </div>
                                </div>
                                <div class="grid grid-cols-2 form-row">
                                    <div>
                                        <BaseInput label="Função" v-model="userAdmin.role" />
                                    </div>
                                </div>
                                <div class="grid grid-cols-2 form-row">
                                    <div>
                                        <BaseTextarea rows="7" label="Um breve resumo seu (250 caracteres)" v-model="userAdmin.resume" :maxlength="250" />
                                    </div>
                                    <div>
                                        <BaseTextarea rows="7" label="Um resumo de suas funções na empresa (250 caracteres)" v-model="userAdmin.assignments" :maxlength="250" />
                                    </div>
                                </div>
                                <div class="grid grid-cols-3" v-if="isCurrentUserAdmin && routeName !== 'my-profile'">
                                    <div class="flex flex-col justify-start">
                                        <label class="label" v-if="userAdmin.id">Tem Acesso ao portal administrativo?</label>
                                        <div class="flex justify-start mt-4">
                                            <div v-if="userAdmin.status && userAdmin.id" class="flex gap-x-4">
                                                <span class="text-green-500 font-semibold flex mt-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                                    </svg>
                                                    Sim
                                                </span>
                                                <BaseButton skin="danger-small" @click="toggleStatus">Revogar Acesso</BaseButton>
                                            </div>
                                            <div class="flex gap-x-4" v-if="!userAdmin.status && userAdmin.id">
                                                <span class="text-red-600 font-semibold flex mt-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                    Não
                                                </span>
                                                <BaseButton skin="primary-small" @click="toggleStatus">Reativar</BaseButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-start" v-if="userAdmin.id">
                                        <label class="label">Gerar uma nova senha para este usuário</label>
                                        <BaseButton class="mt-4" skin="danger-outline-small" @click="resetPassword">Resetar Senha</BaseButton>
                                    </div>
                                    <div class="flex flex-col justify-start" v-if="userAdmin.id">
                                        <label class="label">Permissões Adminitrativas</label>
                                        <div class="flex justify-start mt-6">
                                            <BaseSlider v-model="userAdmin.is_admin" :label="userAdmin.is_admin ? 'É administrador' : 'Não é administrador'" />
                                        </div>
                                    </div>
                                </div>
                        </template>
                        <template v-slot:actions>
                            <BaseButton skin="primary" :is-submit="true">{{ isEdit ? 'Atualizar': 'Salvar' }}</BaseButton>
                        </template>
                    </BaseFormContainer>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

// Bloquear PROFILE com ID dos oturos pra quem não é ADMIN, verificar is_admin

import { ref, computed, watch } from 'vue';
import BackButton from '@/components/BackButton';
import { useRoute, useRouter } from 'vue-router';
import MaskService from '../services/MaskService';
import ThumbRounded from '@/components/ThumbRounded';
import FileUploader from '@/components/FileUploader';
import { UserAdminService } from '../services/Entity';
import AuthService from '../services/AuthService';
import Swal from 'sweetalert';
import { useVuelidate } from '@vuelidate/core'
import { 
    requiredMessage,
    minLengthMessage,
    emailMessage
} from '../services/VuelidateMessageService';

export default {
    name: 'Profile',
    components: {
        BackButton,
        ThumbRounded,
        FileUploader
    },
    async setup() {
        const pageHeader = ref('Perfil');
        const formTitle = ref('Adicionar novo usuário');
        const acceptMimeTypeImage = 'image/png,image/gif,image/jpeg,image/webp';
        const route = useRoute();
        const router = useRouter();
        const isEdit = ref(true);
        const loading = ref(false);
        const upload = ref();
        const routeName = ref(route.name);
        const isCurrentUserAdmin = ref(false);
        const userAdmin = ref({
            name: '',
            status: true
        });
        const rules = computed(() => ({
            name: { required: requiredMessage, minLength: minLengthMessage(3) },
            email: { required: requiredMessage, email: emailMessage },
        }));

        async function init() {
            loading.value = true;
            const { id, is_admin } = await AuthService.getAuthInfo();
            isCurrentUserAdmin.value = is_admin;

            if (routeName.value === 'my-profile') {
                await getUserAdminById(id);
                loading.value = false;
                return;
            } else {
                if (!is_admin) {
                    router.push({ name: 'dashboard' });
                }
            }

            await loadData();
            loading.value = false;
        }

        async function loadData() {
            const userAdminId = handleRoute(route);

            if (!userAdminId) {
                isEdit.value = false;
                return;
            }

            isEdit.value = true;
            await getUserAdminById(userAdminId);
            pageHeader.value = `Gerenciamento de Usuário`;
            formTitle.value = `Editar dados de: ${userAdmin.value.name}`;
        }

        function handleRoute(route) {
             const adminUserId = route.params.id;

             return adminUserId ? adminUserId : false;
        }

        async function getUserAdminById(userAdminId) {
            try {
                const { data } = await UserAdminService.getById(userAdminId);

                userAdmin.value = data;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados do usuário',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function submit() {
            const isValid = await v$.value.$validate();
            if (!isValid) return;

            if (!isEdit.value) {
                await save();
            } else {
                await update();
            }
        }

        async function save() {
            const userAdminObject = { ...userAdmin.value };
            
            try {
                loading.value = true;
                const { data } = await UserAdminService.create(userAdminObject);
                loading.value = false;

                Swal({
                    title: `Senha: ${data.plainTexPassword}`,
                    text: 'Caso o novo usuário não receba esta senha por email, envie a mesma para ele. Esta senha só aparece aqui apenas 1 vez',
                    icon: 'success',
                    buttons: {
                        confirm: {
                            text: 'Ok!',
                            className: 'swal-button--success'
                        }
                    },
                }).then(async (confirm) => {
                    router.push({ name: 'profile', params: { id: data.id } });
                });
            } catch(err) {
                loading.value = false;
                console.log(err);

                if (err && err.data && err.data.error &&  err.data.error == "already_exists") {
                    Swal({
                        title: 'Um usuário com este mesmo email já existe',
                        text: 'O email de cada usuário deve ser único',
                        icon: 'warning'
                    });
                    return;
                }

                Swal({
                    title: 'Ocorreu um erro ao salvar o cliente',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function update() {
            const userAdminObject = { ...userAdmin.value };
            
            try {
                loading.value = true;
                const { data } = await UserAdminService.update(userAdminObject);
                await getUserAdminById(data.id);
                loading.value = false;

                Swal({
                    title: 'Usuário Atualizado!',
                    icon: 'success'
                });
            } catch(err) {
                loading.value = false;
                console.log(err);

                if (err && err.data && err.data.error &&  err.data.error == "already_exists") {
                    Swal({
                        title: 'Um usuário com este mesmo email já existe',
                        text: 'O email de cada usuário deve ser único',
                        icon: 'warning'
                    });
                    return;
                }

                Swal({
                    title: 'Ocorreu um erro ao atualizar',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        function resetPassword() {
            Swal('Tem certeza que deseja resetar a senha deste usuário?', {
                text: 'Uma vez que resetar a senha, esta pessoa não conseguirá mais entrar com a senha antiga',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Resetar agora',
                        className: 'swal-button--danger'
                    }
                },
                dangerMode: true
            }).then(async (confirm) => {
                if (confirm) {
                    loading.value = true;
                    const requestData = { id: userAdmin.value.id };
                    const { data } = await UserAdminService.resetPassword(requestData);
                    loading.value = false;

                    Swal({
                        title: `Nova senha: ${data}`,
                        text: 'Caso o novo usuário não receba esta senha por email, envie a mesma para ele. Esta senha só aparece aqui apenas 1 vez',
                        icon: 'success',
                    });
                }
            });
        }

        function toggleStatus() {
            const statusToChange = !userAdmin.value.status;
            const title = statusToChange ? 'Tem certeza que deseja reativar o acesso deste usuário?': 'Tem certeza que deseja bloquear o acesso deste usuário?';
            const text = statusToChange ? 'Uma vez que reativar, essa pessoa conseguirá fazer login novamente' : 'Uma vez que bloquear, esta pessoa será deslogado e não conseguirá mais entrar';
            const buttonClass = statusToChange ? 'swal-button--success' :  'swal-button--danger';
            const textButton = statusToChange ? 'Reativar' : 'Bloquear Agora';

            Swal({
                title,
                text,
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: textButton,
                        className: buttonClass
                    }
                }
            }).then(async (confirm) => {
                if (confirm) {
                    loading.value = true;
                    const { data } = await UserAdminService.toggleStatus(userAdmin.value.id);
                    await getUserAdminById(data.id);
                    loading.value = false;
                    Swal({
                        title: 'Feito!',
                        icon: 'success'
                    });
                }
            });
        }

        function onUpload(data) {
            const { success } = data;
            const file = success[0];

            userAdmin.value.photo = file.id;
            userAdmin.value.photo_url = file.url;
        }

        watch(() => route.params, async (to, from) => {
            if (route.name == 'profile' && (from != to)) {
                loading.value = true;
                await loadData();
                loading.value = false;
            }
        }, { deep: true });

        init();
        const v$ = useVuelidate(rules, userAdmin);

        return {
            routeName,
            toggleStatus,
            pageHeader,
            formTitle,
            userAdmin,
            isCurrentUserAdmin,
            MaskService,
            isEdit,
            resetPassword,
            acceptMimeTypeImage,
            upload,
            onUpload,
            loading,
            v$,
            submit,
            save,
            update
        };
    }
}
</script>

