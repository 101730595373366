
import HttpClient from '../HttpService';

function getById(requestId) {
    return HttpClient.get(`/tasks/${requestId}`);
}

function create(data) {
    return HttpClient.post(`/tasks`, data);
}

function list(page, limit, data) {
    return HttpClient.post(`/tasks/list?page=${page}&limit=${limit}`, data);
}

function update(data) {
    return HttpClient.put(`/tasks/${data.id}`, data);
}

function destroy(requestId) {
    return HttpClient.delete(`/tasks/${requestId}`);
}

export default {
    destroy,
    list,
    create,
    update,
    getById
}
