<template>
    <div>
        <PageHeader :title="pageHeader">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <BackButton :to="jobBackRoute" />
        </ActionArea>

        <div class="flex justify-center mb-6" v-if="loading">
            <Loading text="Buscando Dados  Aguarde..." :show="loading" />
        </div>

        <div class="grid grid-cols-2 gap-x-2" v-if="!loading">
            <div class="col-span-2">
                <form @submit.prevent="submit" novalidate>
                    <BaseFormContainer :title="formTitle">
                        <template v-slot:form>
                                <BaseFormSectionHeader title="Currículo do Candidato" subtitle="Faça o upload do currículo desse candidato"></BaseFormSectionHeader>

                                <div class="flex">
                                    <div class="p-8 flex gap-x-6">
                                        <div class="flex flex-col">
                                            <a class="flex gap-x-4 mt-4 hover:bg-green-100 p-2 rounded-md" target="_blank" :href="candidate.resume_url">
                                                <span class="text-green-500 font-bold">{{ candidate.resume_url ? 'ANEXADO' : 'SEM CURRÍCULO' }}</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" v-if="candidate.resume_url" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                                </svg>
                                            </a>
                                        </div>
                                        <FileUploader class="mt-4" text="Enviar Currículo" :showProgress="true" :accept="acceptMimeTypeImage" @onUpload="onUpload" />
                                        <svg v-if="candidate.resume_url" @click="deleteResume" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-red-600 mt-6 cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                    </div>
                                    
                                    <div class="p-8 flex gap-x-6">
                                        <div class="flex flex-col">
                                            <a class="flex gap-x-4 mt-4 hover:bg-green-100 p-2 rounded-md" target="_blank" :href="candidate.attachment_url">
                                                <span class="text-green-500 font-bold">{{ candidate.attachment ? 'ANEXADO' : 'SEM ARQUIVO' }}</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" v-if="candidate.attachment_url" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                                </svg>
                                            </a>
                                        </div>
                                        <FileUploader2 class="mt-4" text="Enviar Arquivo" :showProgress="true" :accept="acceptMimeTypeImage" @onUpload="onUpload2" />
                                        <svg v-if="candidate.attachment_url" @click="deleteFile" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-red-600 mt-6 cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                    </div> 
                                </div>

                                <BaseFormSectionHeader title="Informações Pessoais" subtitle="Informações Básicas"></BaseFormSectionHeader>
                                <div class="grid grid-cols-4 form-row">
                                    <div>
                                        <BaseInput :is-invalid="v$.name.$error" label="Nome e Sobrenome" @blur="v$.name.$touch" placeholder="Leonardo Da Vinci" v-model="candidate.name" :required="true" />
                                        <div v-for="error in v$.$errors" :key="error.$uid">
                                            <BaseErrorMessage v-if="error.$property === 'name'" :message="error.$message" />
                                        </div>
                                    </div>
                                    <div>
                                        <BaseInput :is-invalid="v$.email.$error" label="Email" @blur="v$.email.$touch" placeholder="leonardo@davinci.com" v-model="candidate.email" :required="true" />
                                        <div v-for="error in v$.$errors" :key="error.$uid">
                                            <BaseErrorMessage v-if="error.$property === 'email'" :message="error.$message" />
                                        </div>
                                    </div>
                                    <div>
                                        <BaseInput label="Link do Candidato" placeholder="Link do Candidato" v-model="candidate.email2" />
                                    </div>
                                    <div>
                                        <BaseInput label="Telefone" placeholder="(11) 99999-9999" v-model="candidate.phone" v-maska="MaskService['phone']"/>
                                    </div>
                                </div>
                                <div class="grid grid-cols-2">
                                    <div>
                                        <BaseSelect :options="statusOptions" label="Situação do candidato" v-model="candidate.status" />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 form-row">
                                    <div>
                                        <BaseTextarea rows="7" label="Observações Adicionais" v-model="candidate.obs" :maxlength="20000" />
                                    </div>
                                </div>
                        </template>
                        <template v-slot:actions>
                            <BaseButton skin="primary" :is-submit="true">{{ isEdit ? 'Atualizar': 'Salvar' }}</BaseButton>
                        </template>
                    </BaseFormContainer>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import { ref, computed, watch } from 'vue';
import BackButton from '@/components/BackButton';
import { useRoute, useRouter } from 'vue-router';
import MaskService from '../services/MaskService';
import FileUploader from '@/components/FileUploader';
import FileUploader2 from '@/components/FileUploader2';
import Swal from 'sweetalert';
import { useVuelidate } from '@vuelidate/core';
import { CandidateService } from '../services/Entity';
import { 
    requiredMessage,
    minLengthMessage,
    emailMessage
} from '../services/VuelidateMessageService';

export default {
    name: 'Candidate',
    components: {
        BackButton,
        FileUploader,
        FileUploader2
    },
    setup() {
        const pageHeader = ref('Gerenciamento de Candidato');
        const formTitle = ref('Adicionar novo Candidato');
        const acceptMimeTypeImage = '*';
        const route = useRoute();
        const router = useRouter();
        const isEdit = ref(true);
        const loading = ref(false);
        const jobBackRoute = ref({});
        const upload = ref();
        const statusOptions = ref([
            { title: 'Na lista', value: 'list' },
            { title: 'Apto para entrevista', value: 'interview_available' },
            { title: 'Entrevista Concluida', value: 'interview_done' },
            { title: 'Aprovado', value: 'approved' },
            { title: 'Reprovado', value: 'disapproved' },
        ]);
        const candidate = ref({
            name: '',
            status: 'list'
        });

        const rules = computed(() => ({
            name: { required: requiredMessage, minLength: minLengthMessage(3) },
            email: { required: requiredMessage, email: emailMessage },
        }));
        
        async function init() {
            loading.value = true;
            await loadData();
            loading.value = false;
        }

        async function loadData() {
            const { candidateId, jobId } = handleRoute(route);
            jobBackRoute.value = { name: 'job', params: { id: jobId }};

            if (!candidateId) {
                candidate.value.job = jobId;
                isEdit.value = false;
                return;
            }

            isEdit.value = true;
            await getCandidateById(candidateId);
            pageHeader.value = `Gerenciamento de Candidato`;
            formTitle.value = `Editar dados do candidato: ${candidate.value.name}`;
        }

        function handleRoute(route) {
            const jobId = route.params.job_id;
            const candidateId = route.params.id;

            if (!jobId) {
                router.push({ name: 'jobs' });
                return;
            }

             return {
                 jobId: jobId ? jobId : false,
                 candidateId: candidateId ? candidateId : false
             }
        }

        async function getCandidateById(candidateId) {
            try {
                const { data } = await CandidateService.getById(candidateId);

                candidate.value = data;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados desse candidato',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function submit() {
            const isValid = await v$.value.$validate();
            if (!isValid) return;

            if (!isEdit.value) {
                await save();
            } else {
                await update();
            }
        }

        async function save() {
            const candidateObject = { ...candidate.value };
            
            try {
                loading.value = true;
                const { data } = await CandidateService.create(candidateObject);
                router.push({ name: 'candidate', params: { job_id: data.job.id,  id: data.id }  });
                await loadData();
                loading.value = false;

                Swal({
                    title: 'Candidato Salvo!',
                    icon: 'success'
                });
            } catch(err) {
                loading.value = false;
                console.log(err);

                Swal({
                    title: 'Ocorreu um erro ao salvar',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function update() {
            const candidateObject = { ...candidate.value };
            
            try {
                loading.value = true;
                const { data } = await CandidateService.update(candidateObject);
                await getCandidateById(data.id);
                loading.value = false;

                Swal({
                    title: 'Candidato Atualizado!',
                    icon: 'success'
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        function onUpload(data) {
            const { success } = data;
            const file = success[0];

            candidate.value.resume = file.id;
            candidate.value.resume_url = file.url;
        }

        function onUpload2(data) {
            const { success } = data;
            const file = success[0];

            candidate.value.attachment = file.id;
            candidate.value.attachment_url = file.url;
        }

        async function deleteResume() {
            Swal({
                title: 'Tem certeza que deseja remover esse arquivo?',
                text: 'Após remover, é preciso clicar em atualizar',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Remover',
                        className: 'swal-button--danger'
                    }
                },
                dangerMode: true
            }).then(async (confirm) => {
                if (confirm) {
                    candidate.value.resume = null;
                    delete candidate.value.resume_url;
                }
            });
        }

        async function deleteFile() {
            Swal({
                title: 'Tem certeza que deseja remover esse arquivo?',
                text: 'Após remover, é preciso clicar em atualizar',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Remover',
                        className: 'swal-button--danger'
                    }
                },
                dangerMode: true
            }).then(async (confirm) => {
                if (confirm) {
                    candidate.value.attachment = null;
                    delete candidate.value.attachment_url;
                }
            });
        }

        init();
        const v$ = useVuelidate(rules, candidate);

        watch(() => route.params, async (to, from) => {
            if (route.name == 'candidate' && (from != to)) {
                await init();
            }
        }, { deep: true });

        return {
            deleteResume,
            deleteFile,
            onUpload2,
            pageHeader,
            formTitle,
            candidate,
            loadData,
            MaskService,
            isEdit,
            jobBackRoute,
            acceptMimeTypeImage,
            upload,
            statusOptions,
            onUpload,
            v$,
            submit,
            save,
            update,
            loading
        };
    },
}
</script>