<template>
    <div>
        <PageHeader :title="pageTitle">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <BackButton :to="{ name: 'tasks' }" />
        </ActionArea>

        <div class="flex justify-center">
            <Loading text="Buscando solicitações... ..." :show="loading" />
        </div>

        <div class="grid grid-cols-1" v-if="!loading">
            <div class="card p-10">
                <div class="flex justify-end">
                    <BaseButton :to="{ name: 'taskform', params: { id_task: task.id } }" skin="primary">Editar</BaseButton>
                </div>
                <div class="grid grid-cols-4 mb-6">
                    <BaseSelect class="mt-4" :disabled="loadingUpdate" :disable-empty-option="true" :options="statusOptions" label="Mudar para" v-model="task.status" @change="update" />
                    <svg v-if="loadingUpdate" class="animate-spin ml-4 mt-16 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
                <div class="grid grid-cols-4">
                    <div>
                        <label class="label">Responsável</label>
                        <span class="text-green-500 text-sm font-semibold">{{ task.admin.name }}</span>
                    </div>
                    <div>
                        <label class="label">Solicitante</label>
                        <span class="text-green-500 text-sm font-semibold">{{ task.admin_requester ? task.admin_requester.name : '--' }}</span>
                    </div>
                    <div>
                        <label class="label">Status</label>
                        <Tag v-if="task.status === 'open'" type="warning" text="aberta" />
                        <Tag v-if="task.status === 'in_progress'" type="info" text="em progresso" />
                        <Tag v-if="task.status === 'done'" type="success" text="feito" />
                        <Tag v-if="task.status === 'canceled'" type="danger" text="cancelada" />
                    </div>
                </div>
                <div class="grid grid-cols-1 mt-10">
                    <div>
                        <label class="label">Descrição</label>
                        <span class="text-green-500 text-sm font-semibold">{{ task.description }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-1" v-if="!loading">
            <div>
                <FileTable class="mt-2" v-if="task.files && task.files.length" :files="task.files" :delete-control="false" />
            </div>
        </div>

        <div class="grid grid-cols-1 mt-4" v-if="!loading">
            <div class="card p-10">
                <div class="grid grid-cols-3">
                    <div>
                        <BaseSelect :options="adminOptions" empty-option="Não mencionar ninguém" label="Mencionar" v-model.number="mentionItem" @change="mention" />
                    </div>
                    <div class="ml-4 col-span-2">
                        <span class="text-green-500 text-sm font-semibold">Pessoas que receberão um alerta no email: ({{ mentions.length }}) email(s)</span>
                        <div class="bg-gray-100 flex gap-x-2 h-auto mt-4 p-1 flex-wrap gap-y-2">
                            <span v-for="mention in mentions" :key="mention" class="flex w-auto h-7 px-4 py-1 rounded-lg bg-green-500 text-white font-medium text-sm">{{ mention.name }}
                                <svg xmlns="http://www.w3.org/2000/svg" @click="removeMention(mention.id)" class="ml-2 mt-1 h-4 w-4 font-semibold cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <BaseTextarea rows="7" label="Adicionar um comentário" v-model="taskNote.description" :maxlength="10000" id="taskNoteField" />
                    <FileUploader class="mt-4" text="Adicionar Arquivos" :multiple="true" :showProgress="true" accept="*" @onUpload="onUpload" />
                    <FileTable class="mt-2" :loading="loadingFiles" :files="taskNote.files" @removeFile="removeTaskNoteFile" />
                    <div class="flex justify-end mt-8">
                        <BaseButton skin="primary" @click="submit" :disabled="loadingSubmitComment || !taskNote.description">
                        <svg v-if="loadingSubmitComment" class="animate-spin ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>

                        {{ isEditComment ? 'Atualizar': 'Enviar Comentário' }}
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-1 mt-4" v-if="!taskNotes.length && !loading">
            <Alert text="Ainda não existem comentários" :show="!taskNotes.length && !loading" />
        </div>

        <div class="flex justify-center">
            <Loading text="Atualizando comentários..." :show="loadingTaskNotes" />
        </div>

        <div class="grid grid-cols-1 mt-4" v-if="!loadingTaskNotes">
            <div v-for="tasknote in taskNotes" :key="tasknote.id" v-show="taskNotes.length">
                <TaskNoteCard @editTaskNote="editNote" class="mt-4" :tasknote="tasknote" :logged-user-id="loggedAdmin.id" />
                <FileTable class="mt-2" v-if="tasknote.files && tasknote.files.length" :files="tasknote.files" :delete-control="false" />
                <BaseButton v-if="loggedAdmin.has_delete" class="py-2" skin="danger-small" @click="deleteTaskNote(tasknote.id)">Excluir Interação</BaseButton>
            </div>
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';
import { TaskService, TaskNoteService, UserAdminService } from '../services/Entity';
import Swal from 'sweetalert';
import { defaultDate } from '../filters/date';
import { useRoute, useRouter } from 'vue-router';
import Tag from '@/components/Tag';
import BackButton from '@/components/BackButton';
import FileTable from '@/components/FileTable';
import FileUploader from '@/components/FileUploader';
import TaskNoteCard from '@/components/TaskNoteCard';
import Alert from '@/components/Alert';
import AuthService from '../services/AuthService';
import UtilService from '../services/UtilService';

export default {
    components: {
        Tag,
        BackButton,
        FileTable,
        FileUploader,
        TaskNoteCard,
        Alert
    },
    setup() {
        const pageTitle = ref('Detalhes da Demanda Interna');
        const taskNotes = ref([]);
        const loadingFiles = ref(false);
        const isEditComment = ref(false);
        const loadingSubmitComment = ref(false);
        const loadingTaskNotes = ref(false);
        const adminOptions = ref([]);
        const loggedAdmin = AuthService.getAuthInfo();
        const mentions = ref([]);
        const mentionItem = ref();
        const taskNote = ref({
            files: []
        });
        const task = ref({
            admin: {},
        });
        const loading = ref(false);
        const loadingUpdate = ref(false);
        const route = useRoute();
        const router = useRouter();
        const statusOptions = ref([
            { title: 'Aberta', value: 'open' },
            { title: 'Em progresso', value: 'in_progress' },
            { title: 'Finalizado', value: 'done' },
            { title: 'Cancelada', value: 'canceled' }
        ]);

        async function init() {
            loading.value = true;
            await loadAdmins();
            await loadData()
            loading.value = false;
        }

        async function loadData() {
            const taskId = handleRoute(route);
            await getTaskById(taskId);
            await loadTaskNotes();
        }

        function handleRoute(route) {
            if (!route.params.id) {
                router.push({ name: 'tasks' });
                return;
            }

            return route.params.id;
        }

        async function getTaskById(taskId) {
            try {
                const { data } = await TaskService.getById(taskId);
                task.value = data;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados da demanda',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function update() {
            try {
                loadingUpdate.value = true;
                const taskObject  = { ...task.value };
                const { data } = await TaskService.update({ id: taskObject.id, status: taskObject.status, admin: taskObject.admin.id });
                task.value = data;
                loadingUpdate.value = false;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar o status da demanda',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadTaskNotes() {
            try {
                loadingTaskNotes.value = true;
                const listFilter = { page: 1, limit: 9999}
                const { data: { total, items } } = await TaskNoteService.list(listFilter.page, listFilter.limit, { task: task.value.id });
                taskNotes.value = items;
                loadingTaskNotes.value = false;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter os comentários',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function submit() {
            if (!isEditComment.value) {
                await saveComment();
            } else {
                await updateComment();
            }
        }

        function resetTaskNote() {
            mentionItem.value = null;
            isEditComment.value = false;
            mentions.value = [];
            taskNote.value = {};
            taskNote.value.description = '';
            taskNote.value.files = [];
        }

        async function saveComment() {
            try {
                loadingSubmitComment.value = true;
                const taskNoteObject  = { ...taskNote.value, task: task.value.id, admin: loggedAdmin.id, mentions: mentions.value };

                await TaskNoteService.create(taskNoteObject);
                resetTaskNote();
                loadingSubmitComment.value = false;
                await loadTaskNotes(task.value.id);
            } catch(err) {
                loading.value = false;
                loadingSubmitComment.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar o status da demanda',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function updateComment() {
            try {
                const taskNoteObject  = { ...taskNote.value, mentions: mentions.value };
                loadingSubmitComment.value = true;
                await TaskNoteService.update(taskNoteObject);
                loadingSubmitComment.value = false;
                resetTaskNote();
                await loadTaskNotes(task.value.id);
            } catch(err) {
                loading.value = false;
                loadingSubmitComment.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar o comentário',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        function onUpload(data) {
            const { success } = data;
            const file = success[0];

            taskNote.value.files.push(file);
        }

        async function removeTaskNoteFile(fileId) {
            try {
                Swal({
                title: 'Deseja realmente remover o arquivo?',
                text: 'Não será possível restaurar o mesmo',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Deletar',
                        className: 'swal-button--danger'
                    }
                }}).then(async (confirm) => {
                    if (!confirm) return;

                    const requestData = { tasknote: taskNote.value.id, file: fileId };
                    loadingFiles.value = true;
                    taskNote.value.files.forEach((file, index) => {
                        if (file.id == fileId) {
                            taskNote.value.files.splice(index, 1);
                        }
                    });

                    await TaskNoteService.removeFile(requestData.tasknote, requestData.file, requestData);
                    loadingFiles.value = false;
                });
            } catch(err) {
                loadingFiles.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao excluir o arquivo',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        function editNote(tasknote) {
            isEditComment.value = true;
            taskNote.value = { ...tasknote, admin: tasknote.admin.id };
            UtilService.scrollTo(document.getElementById('taskNoteField'), window);
        }

        async function deleteTaskNote(taskNoteId) {
            Swal({
                title: 'Tem certeza que deseja excluir essa interação',
                text: 'Uma vez que excluir, não será possível recuperar',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Excluir Interação',
                        className: 'swal-button--danger'
                    }
                },
                dangerMode: true
            }).then(async (confirm) => {
                if (confirm) {
                    loadingTaskNotes.value = true;
                    const { data: { data } } = await TaskNoteService.destroy(taskNoteId);
                    await loadTaskNotes();

                    Swal({
                        title: `Excluido`,
                        icon: 'success',
                    });
                }
            });
        }

        async function loadAdmins() {
            try {
                const listingFilter = { page: 1, limit: 9999 };
                const { data: { total, items } } = await UserAdminService.list(listingFilter.page, listingFilter.limit);

                adminOptions.value = items.map(admin => {
                    return {
                        email: admin.email,
                        title: admin.name,
                        value: admin.id
                    }
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos colaboradores',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        function mention() {
            adminOptions.value.forEach(function(admin) {
                if (admin.value == mentionItem.value) {
                    mentions.value.push({
                        id: admin.value,
                        name: admin.title,
                        email: admin.email
                    });
                }
            });
        }

        function removeMention(id) {
            mentions.value.forEach(function(mention, index) {
                if (mention.id == id) {
                    mentions.value.splice(index, 1);
                }
            });
        }

        init();

        return {
            mentionItem,
            mentions,
            removeMention,
            mention,
            loadAdmins,
            adminOptions,
            deleteTaskNote,
            loadingTaskNotes,
            loggedAdmin,
            editNote,
            taskNotes,
            loadingSubmitComment,
            saveComment,
            submit,
            isEditComment,
            loadingFiles,
            loadTaskNotes,
            onUpload,
            updateComment,
            removeTaskNoteFile,
            taskNote,
            update,
            loadingUpdate,
            defaultDate,
            getTaskById,
            statusOptions,
            loadData,
            pageTitle,
            task,
            loading
        };
    },
}
</script>