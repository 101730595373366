<template>
    <div>
        <div class="flex flex-col">
            <div class="flex gap-x-4">
                <div class="h-20 w-20 bg-green-500 flex justify-center text-4xl items-center font-extrabold text-white">
                    {{ dateInfo.day }}
                </div>
                <span class="text-green-500 text-3xl font-bold mt-5">{{ dateInfo.month }}/ {{ dateInfo.year }}</span>
                <span class="text-gray-400 text-2xl font-bold mt-6">{{ meeting.start_time || '--' }} | {{ meeting.end_time || '--' }}</span>
            </div>
            <div class="ml-24 -mt-6">
                <span class="text-gray-400 text-sm font-bold mt-6">Ultima vez atualizado: {{ dateTime(meeting.updated_at) }}</span>
            </div>
        </div>
        <div class="card mt-4 p-10">
            <BaseButton skin="primary" :to="{ name: 'meeting', params: { id: meeting.id }}">Editar Notas</BaseButton>
            <div class="grid grid-cols-1">
                <span class="text-green-500 text-2xl font-bold mt-5">{{ meeting.admin.name }}</span>
            </div>
            <div class="grid grid-cols-1 form-row">
                <label class="label">Quem foram os participantes dessa reunião?</label>
                <p class="text-gray-700 text-md font-medium text-justify">{{ meeting.participants }}</p>
            </div>
            <div class="grid grid-cols-2 mt-10">
                <div>
                    <label class="label">Observações privadas, só a megaluzz pode ler</label>
                    <p class="text-gray-700 text-md font-medium text-justify">{{ meeting.private_obs }}</p>
                </div>
                <div>
                    <label class="label">Observações que o cliente pode ler</label>
                    <p class="text-gray-700 text-md font-medium text-justify">{{ meeting.public_obs }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { computed } from 'vue';
import { DateTime } from 'luxon';
import { dateTime } from '../filters/date';

export default {
    props: {
        meeting: Object
    },
    setup(props) {
        const dateInfo = computed(() => {
            const extractedDate = props.meeting.new_date ? DateTime.fromISO(props.meeting.new_date) : DateTime.fromISO(props.meeting.created_at);

            return {
                day: extractedDate.day,
                month: extractedDate.toFormat('LLLL'),
                year: extractedDate.year,
                hour: extractedDate.toFormat('HH'),
                minutes: extractedDate.toFormat('mm')
            }
        });

        return { 
            dateInfo,
            dateTime
        }
    }
}
</script>
