<template>
    <div>
        <PageHeader :title="pageTitle">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <BaseButton :to="{ name: 'meeting' }" skin="primary">
                <template v-slot:icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                </template>
                Adicionar
            </BaseButton>
        </ActionArea>

        <div class="grid grid cols-1 mb-4" v-if="!loadingEntireScreen">
            <BaseFormContainer title="Filtrar por:">
                    <template v-slot:form>
                        <form v-on:submit.prevent>
                            <div class="grid grid-cols-4 gap-x-8">
                                <div class="flex gap-x-4">
                                    <BaseInput label="Nome do Cliente" v-model="filter.companyName" v-on:keyup.enter="loadData" />
                                    <BaseButton skin="primary" class="mt-6" @click="loadData">Filtrar</BaseButton>
                                </div>
                            </div>
                        </form>
                    </template>
            </BaseFormContainer>
        </div>

        <div class="flex justify-center mb-4">
            <Loading text="Buscando solicitações... ..." :show="loading" />
        </div>

        <Alert text="Ainda não existem reuniões cadastradas" :show="!meetings.length && !loading" />

        <div class="table-container" v-if="meetings.length && !loadingEntireScreen">
            <table class="min-w-full">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Data</th>
                        <th>Responsável pelo Andamento</th>
                        <th>Cliente</th>
                        <th>Participantes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" v-for="(meeting, index) in meetings" :key="meeting.id">
                        <td>
                            <div class="flex items-center">
                                <div>
                                    <div class="text-sm leading-5 text-gray-800">#{{ index + 1 }}</div>
                                </div>
                            </div>
                        </td>
                        <td class="text-left text-sm leading-5 flex gap-x-8">
                            <BaseButton skin="primary-outline" :to="{ name: 'notes', params: { company_id: meeting.company.id } }">Ver Notas</BaseButton>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-700 font-bold">{{ meeting.new_date ? defaultDate(meeting.new_date) : defaultDate(meeting.created_at) }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-700 font-bold">{{ meeting.admin.name }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-700">{{ meeting.company.name }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-700">{{ meeting.participants && meeting.participants.length > 50 ? meeting.participants.substring(0, 50) + '...' : meeting.participants }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination :total="totalItens" :limit="limit" @changePage="changePagination" />
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';
import Alert from '@/components/Alert';
import Pagination from '@/components/Pagination';
import { defaultDate } from '../filters/date';
import { MeetingService } from '../services/Entity';
import Swal from 'sweetalert';

export default {
    components: {
        Alert,
        Pagination
    },
    setup() {
        const pageTitle = ref('Reuniões');
        const page = ref(1);
        const limit = ref(50);
        const filter = ref({});
        const totalItens = ref(0);
        const loading = ref(false);
        const loadingEntireScreen = ref(false);
        const meetings = ref([]);
        
        async function init() {
            loadingEntireScreen.value = true;
            await loadData()
            loadingEntireScreen.value = false;
        }

        async function loadData() {
            loading.value = true;
            await loadMeetings(filter.value);
            loading.value = false;
        }

        async function loadMeetings(filter) {
            try {
                const listingFilter = { page: page.value, limit: limit.value };
                const { data: { total, items } } = await MeetingService.list(listingFilter.page, listingFilter.limit, filter);

                meetings.value = items;
                totalItens.value = total;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados das reuniões',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function changePagination(currentPage) {
            page.value = currentPage;
            loading.value = true;
            await loadMeetings(page.value, limit.value, filter.value);
            loading.value = false;
        }

        init();

        return {
            pageTitle,
            defaultDate,
            loading,
            changePagination,
            loadData,
            totalItens,
            page,
            filter,
            limit,
            loadingEntireScreen,
            meetings
        };
    },
}
</script>