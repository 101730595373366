<template>
    <div>
        <PageHeader :title="pageTitle">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="page-header-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <BaseButton to="perfil" skin="primary">
                <template v-slot:icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                </template>
                Adicionar
            </BaseButton>
        </ActionArea>

        <div class="grid grid cols-1 mb-4" v-if="!loadingEntireScreen">
            <BaseFormContainer title="Filtros" subtitle="Busque os resultados agrupados por parâmetro">
                    <template v-slot:form>
                        <form v-on:submit.prevent>
                            <div class="grid grid-cols-4 gap-x-8">
                                <div>
                                    <BaseInput label="Nome" v-model="filter.name" v-on:keyup.enter="loadData" />
                                </div>
                                <div>
                                    <BaseInput label="Email" v-model="filter.email" v-on:keyup.enter="loadData" />
                                </div>
                                <div>
                                    <BaseSelect :options="statusOptions" :disable-empty-option="true" label="Status" v-model="filter.status" @change="loadData" />
                                </div>
                            </div>
                        </form>
                    </template>
            </BaseFormContainer>
        </div>
        
        <div class="flex justify-center mb-4">
            <Loading text="Buscando informações... ..." :show="loading" />
        </div>

        <Alert text="Ainda não existem cargos cadastrados" :show="!admins.length && !loadingEntireScreen" />
        <div class="table-container" v-if="admins.length && !loading && !loadingEntireScreen">
            <table class="min-w-full">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Nome</th>
                        <th>Cargo</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" v-for="(admin, index) in admins" :key="admin.id">
                        <td class="">
                            <div class="flex items-center">
                                <div>
                                    <div class="text-sm leading-5 text-gray-800">#{{ index + 1 }}</div>
                                </div>
                            </div>
                        </td>
                        <td class="text-left text-sm leading-5 flex gap-x-8">
                            <BaseButton v-if="loggedUserId !== admin.id" skin="primary-outline" :to="{ name: 'profile', params: { id: admin.id } }">Editar</BaseButton>
                        </td>
                        <td>
                            <ThumbRounded :path="admin.photo_url" />
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-blue-900">{{ admin.name }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-blue-900">{{ admin.role }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-blue-900">{{ admin.email }}</span>
                        </td>
                        <td>
                            <Tag :text="admin.status ? 'ativo':'desativado'" :type="admin.status ? 'success':'danger'" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination :total="totalItens" :limit="limit" @changePage="changePagination" />
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';
import Alert from '@/components/Alert';
import ThumbRounded from '@/components/ThumbRounded';
import Tag from '@/components/Tag';
import Swal from 'sweetalert';
import { UserAdminService } from '../services/Entity';
import { useRoute, useRouter } from 'vue-router';
import Pagination from '@/components/Pagination';
import AuthService from '../services/AuthService';

export default {
    components: {
        Pagination,
        ThumbRounded,
        Alert,
        Tag
    },
    setup() {
        const pageTitle = ref('Usuários com acesso ao portal administrativo');
        const page = ref(1);
        const limit = ref(20);
        const totalItens = ref(0);
        const loading = ref(false);
        const loadingEntireScreen = ref(false);
        const admins = ref([]);
        const router = useRouter();
        const loggedUserId = ref();
        const filter = ref({
            status: 'true'
        });
        const statusOptions = ref([
            { title: 'Todos', value: 'all' },
            { title: 'Ativos', value: true },
            { title: 'Inativos', value: false },
        ]);

        async function init() {
            loadingEntireScreen.value = true;
            const { is_admin } = await AuthService.getAuthInfo();
            const { id } = AuthService.getAuthInfo();

            loggedUserId.value = id;
            
            if (!is_admin) {
                router.push({ name: 'dashboard' });
                return;
            }

            await loadData()
            loadingEntireScreen.value = false;
        }

        async function loadData() {
            loading.value = true;
            await loadAdmins(filter.value);
            loading.value = false;
        }

        async function loadAdmins(filter) {
            try {
                const listingFilter = { page: page.value, limit: limit.value };
                const { data: { total, items } } = await UserAdminService.list(listingFilter.page, listingFilter.limit, filter);

                admins.value = items;
                totalItens.value = total;
                loading.value = false;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao listar os usuários administrativos',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        init();

        return {
            pageTitle,
            admins, 
            filter,
            page,
            limit,
            loggedUserId,
            totalItens,
            loadData,
            loading,
            loadingEntireScreen,
            statusOptions
        };
    },
}
</script>