<template>
    <div>
        <PageHeader :title="pageTitle">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <BaseButton :to="{ name: 'taskform' }" skin="primary">
                <template v-slot:icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                </template>
                Adicionar
            </BaseButton>
        </ActionArea>

        <div class="grid grid cols-1 mb-4" v-if="!loadingEntireScreen">
            <BaseFormContainer title="Filtrar por:">
                <template v-slot:form>
                    <form>
                        <div class="grid grid-cols-6 gap-x-8">
                            <div class="flex flex-col">
                                <label class="label">De</label>
                                <Datepicker locale="pt-BR" :enableTimePicker="false" format="dd/MM/yyyy" v-model="filter.start_date" autoApply @update:modelValue="loadData" />
                            </div>
                            <div class="flex flex-col">
                                <label class="label">Até</label>
                                <Datepicker locale="pt-BR" :enableTimePicker="false" format="dd/MM/yyyy" v-model="filter.end_date" autoApply @update:modelValue="loadData" />
                            </div>
                            <div>
                                <BaseSelect :options="adminOptions" emptyOption="Todos" label="Responsável" v-model="filter.admin" @change="loadData" />
                            </div>
                            <div>
                                <BaseSelect :options="statusOptions" emptyOption="Todos" label="Status" v-model="filter.status" @change="loadData" />
                            </div>
                        </div>
                    </form>
                </template>
            </BaseFormContainer>
        </div>

        <div class="flex justify-center">
            <Loading text="Buscando demandas... ..." :show="loading" />
        </div>

        <Alert text="Não existem demandas para esse filtro" :show="!tasks.length && !loading" />

        <div class="table-container" v-if="tasks.length && !loadingEntireScreen">
            <table class="min-w-full">
                <thead>
                    <tr>
                        <th></th>
                        <th>Dt. Abertura</th>
                        <th>Solicitante</th>
                        <th>Responsável</th>
                        <th>Status</th>
                        <th>Descrição</th>
                        <th v-if="authInfo.has_delete"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" v-for="task in tasks" :key="task.id">
                        <td class="text-left text-sm leading-5 flex gap-x-8">
                            <BaseButton skin="primary-outline" :to="{ name: 'task', params: { id: task.id }}">Ver</BaseButton>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-900">{{ defaultDate(task.created_at) }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-700 font-semibold">{{ task.admin_requester ? task.admin_requester.name : ' -- ' }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-700 font-semibold">{{ task.admin ? task.admin.name : ' -- ' }}</span>
                        </td>
                        <td>
                            <Tag v-if="task.status === 'open'" type="warning" text="aberta" />
                            <Tag v-if="task.status === 'in_progress'" type="info" text="em progresso" />
                            <Tag v-if="task.status === 'done'" type="success" text="feito" />
                            <Tag v-if="task.status === 'canceled'" type="danger" text="cancelada" />
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-900">{{ task.description.substring(0, 40) }}...</span>
                        </td>
                        <td v-if="authInfo.has_delete">
                            <BaseButton skin="danger-small" @click="deleteRequest(task.id)">Excluir</BaseButton>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination :total="totalTasks" :limit="limit" @changePage="changePagination" />
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';
import PageHeader from '@/components/PageHeader';
import Tag from '@/components/Tag';
import { TaskService, UserAdminService } from '../services/Entity';
import Alert from '@/components/Alert';
import Pagination from '@/components/Pagination';
import Swal from 'sweetalert';
import { defaultDate } from '../filters/date';
import { DateTime } from 'luxon';
import AuthService from '../services/AuthService';

export default {
    components: {
        PageHeader,
        Tag,
        Alert,
        Pagination
    },
    setup() {
        const totalTasks = ref(0);
        const page = ref(1);
        const limit = ref(20);
        const pageTitle = ref('Demandas Internas');
        const tasks = ref([]);
        const loading = ref(false);
        const loadingEntireScreen = ref(false);
        const adminOptions = ref([]);
        const authInfo = AuthService.getAuthInfo();
        const statusOptions = ref([
            { title: 'Aberta', value: 'open' },
            { title: 'Em progresso', value: 'in_progress' },
            { title: 'Finalizado', value: 'done' },
            { title: 'Cancelada', value: 'canceled' }
        ]);
        const filter = ref({
            start_date: DateTime.now().minus({ days: 30 }),
            end_date: DateTime.now() 
        });

        async function init() {
            loadingEntireScreen.value = true;
            await loadData();
            loadingEntireScreen.value = false;
        }

        async function loadData() {
            loading.value = true;
            await loadTasks(filter.value);
            await loadAdmins();
            loading.value = false;
        }

        async function loadTasks(filter) {
            try {
                const { data: { total, items } } = await TaskService.list(page.value, limit.value, filter);

                tasks.value = items;
                totalTasks.value = total;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados das demandas',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadAdmins() {
            try {
                const listingFilter = { page: 1, limit: 9999 };
                const { data: { total, items } } = await UserAdminService.list(listingFilter.page, listingFilter.limit);

                adminOptions.value = items.map(admin => {
                    return {
                        title: admin.name,
                        value: admin.id
                    }
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos colaboradores',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function changePagination(currentPage) {
            page.value = currentPage;
            loading.value = true;
            await loadTasks(page.value, limit.value, filter.value);
            loading.value = false;
        }

        async function deleteRequest(requestId) {
            Swal({
                title: 'Tem certeza que deseja excluir essa demanda e todas as interações?',
                text: 'Uma vez que excluir, não será possível recuperar',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Excluir Agora',
                        className: 'swal-button--danger'
                    }
                },
                dangerMode: true
            }).then(async (confirm) => {
                if (confirm) {
                    loading.value = true;
                    const { data: { data } } = await TaskService.destroy(requestId);
                    await loadData();

                    Swal({
                        title: `Excluido`,
                        icon: 'success',
                    });
                }
            });
        }

        init();

        return {
            deleteRequest,
            defaultDate,
            loadTasks,
            loadData,
            authInfo,
            statusOptions,
            adminOptions,
            totalTasks,
            limit,
            page,
            pageTitle,
            filter,
            tasks,
            loading,
            loadingEntireScreen,
            changePagination
        };
    },
}
</script>