<template>
    <div>
        <PageHeader title="Mega Arquivos">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <Modal title="Mega Arquivo" action-text="Salvar" :is-disabled="file.file ? false : true" trigger-text="Adicionar" @modalAction="save">
                <template v-slot:icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
                    </svg>
                </template>
                <template v-slot:body>
                    <div class="flex flex-col gap-y-4">
                        <div class="flex flex-col items-center">
                            <span class="text-green-500 mb-2 flex" v-if="file.file">
                                Arquivo Carregado
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </span>
                            <FileUploader class="mt-4 w-2/5" text="Enviar Arquivo" :showProgress="true" @onUpload="onUpload" />
                        </div>
                        <div>
                            <BaseInput label="Descrição do mega arquivo" v-model="file.description" />
                        </div>
                        <div>
                            <BaseSelect :options="companyOptions" empty-option="Arquivos Internos" label="De quem é o mega arquivo?" v-model.number="file.company" />
                        </div>
                    </div>
                </template>
            </Modal>
        </ActionArea>

        <div class="grid grid-cols-5 mb-6 gap-x-4 card p-12" v-if="!loadingEntireScreen">
            <div class="col-span-2">
                <BaseInput label="Filtrar por descrição" v-model="filter.description" v-on:keyup.enter="loadData" />
            </div>
            <div>
                <BaseSelect :options="companyOptions" empty-option="Arquivos Internos" label="Filtrar por cliente" v-model.number="filter.company" @change="loadData" />
            </div>
        </div>
        
        <div class="flex justify-center mb-4">
            <Loading text="Buscando arquivos... ..." :show="loading" />
        </div>

        <Alert text="Não existem arquivos para esse filtro" :show="!files.length && !loading && !loadingEntireScreen" />

        <table class="table min-w-full w-full" v-if="files.length && !loading && !loadingEntireScreen">
            <thead>
                <tr>
                    <th></th>
                    <th>Descrição</th>
                    <th>Tipo</th>
                    <th>Tamanho</th>
                    <th v-if="authInfo.has_delete"></th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-row" v-for="file in files" :key="file.id">
                    <td>
                        <BaseButton skin="primary-outline-small" :external="true" :href="file.url">Ver</BaseButton>
                    </td>
                    <td>
                        <span class="text-sm leading-5 text-blue-900" v-if="!file.edit">{{ file.description.substring(0, 100) }}</span>
                        <BaseInput v-if="file.edit" v-on:keyup.esc="cancelEdit(file)" v-model="file.description" v-on:keyup.enter="edit(file)" />
                        <div v-if="!file.edit">
                            <span class="text-green-500 text-xs font-normal cursor-pointer" @click="toggleEdit(file)">Editar</span>
                        </div>
                    </td>
                    <td>
                        <span class="text-sm leading-5 text-blue-900">{{ getExtension(file.file.description).substring(0, 5) }}</span>
                    </td>
                    <td>
                        <span class="text-sm leading-5 text-blue-900">{{ humanFileSize(file.file.size) }}</span>
                    </td>
                    <td v-if="authInfo.has_delete">
                        <BaseButton skin="danger-small" @click="deleteFile(file.id)">
                            <template v-slot:icon>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                            </template>
                            Excluir
                        </BaseButton>
                    </td>
                </tr>
            </tbody>
        </table>
        <Pagination v-show="files.length && !loading && !loadingEntireScreen" :total="totalFiles" :limit="limit" @changePage="changePagination" />
    </div>
</template>

<script>

import { ref } from 'vue';
import PageHeader from '@/components/PageHeader';
import Modal from '../components/Modal';
import { MegaFilesService, CompanyService } from '../services/Entity';
import Alert from '@/components/Alert';
import Swal from 'sweetalert';
import AuthService from '../services/AuthService';
import FileUploader from '@/components/FileUploader';
import Pagination from '@/components/Pagination';

export default {
    components: {
        Modal,
        PageHeader,
        FileUploader,
        Pagination,
        Alert,
    },
    setup() {
        const totalItens = ref(0);
        const totalFiles = ref(0);
        const page = ref(1);
        const limit = ref(20);
        const loadingEntireScreen = ref(false);
        const files = ref([]);
        const file = ref({});
        const companyOptions = ref([]);
        const loading = ref(false);
        const authInfo = AuthService.getAuthInfo();
        const filter = ref({
            company: null
        });
        
        async function init() {
            loadingEntireScreen.value = true;
            await loadData();
            loadingEntireScreen.value = false;
        }

        async function loadData() {
            loading.value = true;
            await loadCompanies();
            await loadFiles(filter.value);
            loading.value = false;
        }

        async function loadCompanies(filter) {
            try {
                const listingFilter = { page: 1, limit: 9999 };
                const { data: { total, items } } = await CompanyService.list(listingFilter.page, listingFilter.limit, filter);

                totalItens.value = total;
                companyOptions.value = items.map(company => {
                    return {
                        title: company.name,
                        value: company.id
                    }
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos clientes',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadFiles() {
            try {
                loading.value = true;
                const { data: { total, items } } = await MegaFilesService.list(page.value, limit.value, filter.value);

                files.value = items;
                totalFiles.value = total;
                loading.value = false;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos mega arquivos',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function changePagination(currentPage) {
            page.value = currentPage;
            loading.value = true;

            await loadFiles(page.value, limit.value, filter.value);
            loading.value = false;
        }

        async function save() {
            try {
                loading.value = true;
                const { data: { data } } = await MegaFilesService.create(file.value);
                
                resetFile();

                Swal({
                    title: 'Salvo!',
                    icon: 'success'
                });

                await loadFiles(filter.value);
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos mega arquivos',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        function resetFile() {
            file.value = {};
        }

        async function edit(file) {
            try {
                delete file.edit;
                loading.value = true;

                const { data: { data } } = await MegaFilesService.update(file);
                
                Swal({
                    title: 'Atualizado',
                    icon: 'success'
                });

                await loadFiles(filter.value);
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos mega arquivos',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function deleteFile(fileId) {
            Swal({
                title: 'Tem certeza que deseja excluir esse mega arquivo?',
                text: 'Uma vez que excluir, não será possível recuperar',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Excluir Agora',
                        className: 'swal-button--danger'
                    }
                },
                dangerMode: true
            }).then(async (confirm) => {
                if (confirm) {
                    loading.value = true;
                    const { data: { data } } = await MegaFilesService.destroy(fileId);
                    await loadData();

                    Swal({
                        title: `Excluido`,
                        icon: 'success',
                    });
                }
            });
        }

        function onUpload(data) {
            const { success } = data;
            const uploadedFile = success[0];

            file.value.file = uploadedFile.id;
            file.value.description = uploadedFile.description;
            file.value.file_url = uploadedFile.url;
        }

        function getExtension(filename) {
            return filename.split('.').pop();
        }

        function humanFileSize(size) {
            let i = Math.floor( Math.log(size) / Math.log(1024) );
            return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
        }

        function cancelEdit(file) {
            delete file.edit;
            file.description = file.file.description;
        }

        function toggleEdit(file) {
            file.edit = true;
        }

        init();
        
        return {
            totalFiles,
            cancelEdit,
            edit,
            toggleEdit,
            humanFileSize,
            getExtension,
            save,
            onUpload,
            file,
            filter,
            authInfo,
            deleteFile,
            loadData,
            totalItens,
            changePagination,
            companyOptions,
            files,
            loading,
            page,
            limit,
            loadingEntireScreen
        };
    },
}
</script>
