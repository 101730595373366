<template>
    <div class="card shadow-lg">
        <div class="flex justify-center p-6">
            <ThumbRounded size="extra-large" :path="admin.photo_url" />
        </div>
        <div class="text-center px-4">
            <h1 class="text-xl h-20 text-green-500 font-bold">{{ admin.name.substr(0, 40)  }}</h1>
            <div class="text-lg h-16 text-gray-600 font-bold">
                {{ admin.role.substr(0, 30)  }}
                <h4 class="text-xs text-gray-400 font-bold">{{ admin.email  }}</h4>
            </div>
            <hr>
        </div>

        <div class="text-center mt-2">
            <label class="label px-4">Resumo</label>
            <p class="h-32 text-xs font-normal text-gray-700 px-4">{{ admin.resume }}</p>
        </div>

        <div class="text-center">
            <label class="label px-4">Atribuições</label>
            <p class="h-32 text-xs font-normal text-gray-700 px-4">{{ admin.assignments }}</p>
        </div>
    </div>
</template>

<script>

import ThumbRounded from '@/components/ThumbRounded';

export default {
    props: {
        admin: Object
    }, 
    components: {
        ThumbRounded
    }
}
</script>
