<template>
    <div>
        <PageHeader :title="'Gerenciamento de Clientes'">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="page-header-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <BackButton :to="{ name: 'companies' }" />
        </ActionArea>

        <div class="flex justify-center mb-6" v-if="loading">
            <Loading text="Buscando Dados  Aguarde..." :show="loading" />
        </div>

        <div class="grid grid-cols-1" v-if="!loading">
            <form @submit.prevent="submit" novalidate>
                <BaseFormContainer :title="company.name || 'Adicionar novo cliente'">
                    <template v-slot:form>
                        <div class="grid grid-cols-2 gap-x-8">
                            <div>
                                <BaseFormSectionHeader title="Informações Básicas" subtitle="Dados da empresa / Cliente"></BaseFormSectionHeader>
                                <div class="grid grid-cols-3 form-row">
                                    <div>
                                        <BaseInput :is-invalid="v$.name.$error" label="Nome da Empresa" @blur="v$.name.$touch" placeholder="Empresa XYZ" v-model="company.name" :required="true" />
                                        <div v-for="error in v$.$errors" :key="error.$uid">
                                            <BaseErrorMessage v-if="error.$property === 'name'" :message="error.$message" />
                                        </div>
                                    </div>
                                    <div>
                                        <BaseInput label="Email 1" placeholder="empresaxyz@empresa.com" v-model="company.email" />
                                    </div>
                                    <div>
                                        <BaseInput label="Email 2" placeholder="empresaxyz2@empresa.com" v-model="company.email2" />
                                    </div>
                                </div>
                                <div class="grid grid-cols-3 form-row">
                                    <div>
                                        <BaseInput label="Telefone" placeholder="" v-model="company.phone" v-maska="MaskService['phone']"  />
                                    </div>
                                    <div>
                                        <BaseInput label="Whatsapp" placeholder="" v-model="company.whatsapp" v-maska="MaskService['phone']"  />
                                    </div>
                                    <div>
                                        <BaseInput label="CNPJ/CPF" placeholder="" v-model="company.cpf" v-maska="MaskService['document']"  />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 form-row">
                                    <div class="p-8 flex gap-x-6">
                                        <div class="flex flex-col">
                                            <a class="flex gap-x-4 mt-4 hover:bg-green-100 p-2 rounded-md" target="_blank" :href="company.file_url">
                                                <span class="text-green-500 font-bold">{{ company.file_url ? 'ANEXADO' : 'SEM ARQUIVO' }}</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" v-if="company.file_url" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                                </svg>
                                            </a>
                                        </div>
                                        <FileUploader class="mt-4" text="Enviar Arquivo" :showProgress="true" @onUpload="onUpload" />
                                        <svg v-if="company.file_url" @click="deleteFile" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-red-600 mt-6 cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <BaseFormSectionHeader title="Endereço" subtitle="Dados adicionais"></BaseFormSectionHeader>
                                <div class="grid grid-cols-3 form-row">
                                    <div class="col-span-2">
                                        <BaseInput label="Endereço" placeholder="" v-model="company.address" />
                                    </div>
                                    <div>
                                        <BaseInput label="CEP" placeholder="" v-model="company.cep" v-maska="MaskService['cep']"  />
                                    </div>
                                </div>
                                <div class="grid grid-cols-2 form-row">
                                    <div>
                                        <BaseSelect :is-invalid="v$.state.$error" @blur="v$.state.$touch" label="Estado" :options="stateOptions" v-model.number="company.state" @change="loadCities(company.state)" :required="true" />
                                        <div v-for="error in v$.$errors" :key="error.$uid">
                                            <BaseErrorMessage v-if="error.$property === 'state'" :message="error.$message" />
                                        </div>
                                    </div>
                                    <div>
                                        <BaseSelect :is-invalid="v$.city.$error" @blur="v$.city.$touch" label="Cidade" :options="citiesOptions" :disabled="!company.state" v-model.number="company.city" :required="true" />
                                        <div v-for="error in v$.$errors" :key="error.$uid">
                                            <BaseErrorMessage v-if="error.$property === 'city'" :message="error.$message" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:actions>
                        <BaseButton skin="primary" :is-submit="true">{{ isEdit ? 'Atualizar': 'Salvar' }}</BaseButton>
                    </template>
                </BaseFormContainer>
            </form>
        </div>

        <div class="grid grid-cols-1 mt-4" v-if="!loading && company.id">
            <BaseFormContainer title="Usuários com acesso ao portal do cliente">
                <template v-slot:form>
                    <ActionArea>
                        <BaseButton :to="{ name: 'user', params: { company_id: company.id } }" skin="primary">
                            <template v-slot:icon>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                            </template>
                            Adicionar
                        </BaseButton>
                    </ActionArea>

                    <Alert text="Ainda não existem usuários cadastrados para esta empresa" :show="!users.length && !loading" />

                    <div class="mt-4" v-if="users.length && !loading">
                        <table class="min-w-full">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Foto</th>
                                    <th>Nome</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-row" v-for="(user, index) in users" :key="user.id">
                                    <td class="">
                                        <div class="flex items-center">
                                            <div>
                                                <div class="text-sm leading-5 text-gray-800">#{{ index + 1 }}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <BaseButton skin="primary-outline" :to="{ name: 'user', params: { company_id: user.company, user_id: user.id }}">Gerenciar</BaseButton>
                                    </td>
                                    <td>
                                        <ThumbRounded class="mr-12" :path="user.photo_url" />
                                    </td>
                                    <td>
                                        <span class="text-sm leading-5 text-green-700">{{ user.name }}</span>
                                    </td>
                                    <td>
                                        <span class="text-sm leading-5 text-green-700">{{ user.email }}</span>
                                    </td>
                                    <td>
                                        <Tag v-if="user.status" type="success" text="ativo" />
                                        <Tag v-if="!user.status" type="danger" text="inativo" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </BaseFormContainer>
        </div>
    </div>
</template>

<script>

import { ref, computed, watch } from 'vue';
import BackButton from '../components/BackButton.vue';
import Swal from 'sweetalert';
import { useRoute, useRouter } from 'vue-router';
import MaskService from '../services/MaskService';
import ThumbRounded from '../components/ThumbRounded.vue';
import Tag from '../components/Tag.vue';
import Alert from '../components/Alert.vue';
import FileUploader from '@/components/FileUploader';
import { useVuelidate } from '@vuelidate/core'
import { CompanyService, GeoInfoService, UserService } from '../services/Entity';
import { 
    requiredMessage,
    minLengthMessage,
} from '../services/VuelidateMessageService';

export default {
    components: {
        FileUploader,
        BackButton,
        ThumbRounded,
        Tag,
        Alert
    },
    setup() {
        const pageHeader = ref('Cliente');
        const loading = ref(false);
        const isEdit = ref(true);
        const stateOptions = ref([]);
        const citiesOptions = ref([]);
        const users = ref([]);
        const company = ref({
            name: ''
        });
        const route = useRoute();
        const router = useRouter();
        const rules = computed(() => ({
            name: { required: requiredMessage, minLength: minLengthMessage(2) },
            state: { required: requiredMessage },
            city: { required: requiredMessage }
        }));

        async function init() {
            loading.value = true;
            await loadData();
            loading.value = false;
        }

        async function loadData() {
            const routeId = handleRoute(route);
            
            await loadStates();

            if (!routeId) {
                isEdit.value = false;
                return;
            }

            isEdit.value = true;
            await getCompanyById(routeId);
            await loadUsers(company.value.id);
            pageHeader.value = `Editar dados de: ${company.value.name}`;
        }

        function handleRoute(route) {
             return route.params.id ? +route.params.id : false;
        }

        async function getCompanyById(companyId) {
            try {
                const { data } = await CompanyService.getById(companyId);

                if (data && data.state) {
                    data.state = data.state.id;
                }

                if (data && data.city) {
                    await loadCities(data.state);
                    data.city = data.city.id;
                }

                company.value = data;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados do cliente',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadStates() {
            try {
                let countryId = 1;
                const { data } = await GeoInfoService.listStates(countryId);

                stateOptions.value = data.map(state => {
                    return {
                        title: state.name,
                        value: state.id
                    }
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao listar os estados',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadCities(stateId) {
            try {
                if (!stateId) {
                    return;
                }

                const { data } = await GeoInfoService.listCities(stateId);

                citiesOptions.value = data.map(city => {
                    return {
                        title: city.name,
                        value: city.id
                    }
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao listar as cidades',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function submit() {
            const isValid = await v$.value.$validate();
            if (!isValid) return;

            if (!isEdit.value) {
                await save();
            } else {
                await update();
            }
        }

        async function save() {
            const companyObject = { ...company.value, country: 1 };
            
            try {
                loading.value = true;
                const { data } = await CompanyService.create(companyObject);
                router.push({ name: 'company', params: { id: data.id } });
                loading.value = false;

                Swal({
                    title: 'Cliente Salvo!',
                    icon: 'success'
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao salvar o cliente',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function update() {
            const companyObject = { ...company.value };
            
            try {
                loading.value = true;
                const { data } = await CompanyService.update(companyObject);
                await getCompanyById(data.id);
                loading.value = false;

                Swal({
                    title: 'Cliente Atualizado!',
                    icon: 'success'
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadUsers(companyId) {
            try {
                const listingFilter = { page: 1, limit: 9999 };
                const { data } = await UserService.list(listingFilter.page, listingFilter.limit, { company: companyId });

                users.value = data;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao listar usuários desta empresa',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        function onUpload(data) {
            const { success } = data;
            const file = success[0];

            company.value.file = file.id;
            company.value.file_url = file.url;
        }

        async function deleteFile() {
            Swal({
                title: 'Tem certeza que deseja remover esse arquivo?',
                text: 'Após remover, é preciso clicar em atualizar',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Remover',
                        className: 'swal-button--danger'
                    }
                },
                dangerMode: true
            }).then(async (confirm) => {
                if (confirm) {
                    company.value.file = null;
                    delete company.value.file_url;
                }
            });
        }

        watch(() => route.params, async (to, from) => {
            if (route.name == 'company' && (from != to)) {
                await init();
            }
        }, { deep: true });

        init();
        const v$ = useVuelidate(rules, company);

        return {
            deleteFile,
            onUpload,
            loadCities,
            pageHeader,
            company,
            loading,
            MaskService,
            stateOptions,
            citiesOptions,
            isEdit,
            v$,
            users,
            submit,
            save,
            update
        }
    },
}
</script>