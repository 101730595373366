import axios from 'axios';
import AuthService from './AuthService';

const HttpClient = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API
});

HttpClient.interceptors.request.use(config => {
    const { token } = AuthService.getAuthInfo();

    if (token) config.headers = { 'Authorization': token };

    return config;
}, function(err) {
    console.log(err);
});

HttpClient.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.data && error.response.data.error && error.response.data.error === 'invalid_token') {
        window.location.href = '/';
        return;
    }

    return Promise.reject(error.response);
});

export default HttpClient;