<template>
    <div>
        <PageHeader :title="pageTitle">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                </svg>
            </template>
        </PageHeader>

        <div class="grid grid cols-1 mb-4" v-if="!loadingEntireScreen">
            <BaseFormContainer title="Filtrar por:">
                    <template v-slot:form>
                        <form>
                            <div class="grid grid-cols-4 gap-x-8">
                                <div>
                                    <BaseSelect :options="adminOptions" emptyOption="Todos" label="Pessoa" v-model="filter.admin" @change="loadData" />
                                </div>
                                <div class="flex flex-col">
                                    <label class="label">De</label>
                                    <Datepicker locale="pt-BR" :enableTimePicker="false" format="dd/MM/yyyy" v-model="filter.start_date" autoApply @update:modelValue="loadData" />
                                </div>
                                <div class="flex flex-col">
                                    <label class="label">Até</label>
                                    <Datepicker locale="pt-BR" :enableTimePicker="false" format="dd/MM/yyyy" v-model="filter.end_date" autoApply @update:modelValue="loadData" />
                                </div>
                            </div>
                        </form>
                    </template>
            </BaseFormContainer>
        </div>

        <div class="flex justify-center mb-6" v-if="loading">
            <Loading text="Buscando atividades... ..." :show="loading" />
        </div>

        <Alert text="Não existem atividades para esse filtro" :show="!activities.length && !loading" />

        <div class="table-container" v-show="activities.length && !loadingEntireScreen">
            <table class="min-w-full">
                <thead>
                    <tr>
                        <th>Colaborador</th>
                        <th>Data/Hora</th>
                        <th>Descrição</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" v-for="activity in activities" :key="activity.id">
                        <td>
                            <span class="text-sm leading-5 text-green-700 font-semibold">{{ activity.admin.name }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-900">{{ dateTime(activity.created_at) }}</span>
                        </td>
                        <td>
                            <span class="text-sm leading-5 text-green-700 font-semibold">{{ activity.description }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination :total="totalItens" :limit="limit" @changePage="changePagination" />
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';
import Alert from '@/components/Alert';
import Pagination from '@/components/Pagination';
import { dateTime } from '../filters/date';
import { UserAdminService, ActivityService } from '../services/Entity';
import Swal from 'sweetalert';
import { DateTime } from 'luxon';

export default {
    components: {
        Alert,
        Pagination
    },
    setup() {
        const pageTitle = ref('Atividades');
        const filter = ref({
            start_date: DateTime.now().minus({ days: 30 }),
            end_date: DateTime.now() 
        });
        const page = ref(1);
        const limit = ref(50);
        const totalItens = ref(0);
        const loading = ref(false);
        const loadingEntireScreen = ref(false);
        const activities = ref([]);
        const adminOptions = ref();

        async function init() {
            loadingEntireScreen.value = true;
            await loadData()
            loadingEntireScreen.value = false;
        }

        async function loadData() {
            loading.value = true;
            await loadActivities(filter.value);
            await loadAdmins(filter.value);
            loading.value = false;
        }

        async function loadActivities(filter) {
            try {
                const { data: { total, logs } } = await ActivityService.list(page.value, limit.value, filter);
                activities.value = logs;
                totalItens.value = total;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados das atividades',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadAdmins() {
            try {
                const listingFilter = { page: 1, limit: 99999 };
                const { data: { total, items } } = await UserAdminService.list(listingFilter.page, listingFilter.limit);

                adminOptions.value = items.map(admin => {
                    return {
                        title: admin.name,
                        value: admin.id
                    }
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos colaboradores',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function changePagination(currentPage) {
            page.value = currentPage;
            loading.value = true;
            await loadActivities(page.value, limit.value, filter.value);
            loading.value = false;
        }

        init();

        return {
            filter,
            changePagination,
            pageTitle,
            loading,
            loadingEntireScreen,
            activities,
            dateTime,
            limit,
            loadData,
            totalItens,
            adminOptions
        };
    },
}
</script>