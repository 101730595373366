<template>
    <div>
        <label for="inputFileElement" class="btn btn-primary flex gap-x-2 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
            <span>{{ text }}</span>
            <!-- Test Compile -->
        </label>
        <input v-if="!multiple"
            :disabled="showProgressBar"
            id="inputFileElement"
            class="hidden"
            v-bind="$attrs"
            type="file"
            name="files"
            ref="uploader"
            @change="selectFile"
        />

        <input v-if="multiple"
            :disabled="showProgressBar"
            id="inputFileElement"
            class="hidden"
            v-bind="$attrs"
            type="file"
            name="files"
            ref="uploader"
            multiple
            @change="selectFile"
        />

        <div class="relative pt-1" v-if="showProgress && showProgressBar">
            <div class="overflow-hidden h-2 text-xs flex rounded bg-green-200">
                <div
                :style="'width: ' + progress + '%'"
                class="
                    shadow-none
                    flex flex-col
                    text-center
                    whitespace-nowrap
                    text-white
                    justify-center
                    bg-green-600
                "
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import Swal from 'sweetalert';
import UploadService from '../services/FileService';

export default {
    name: 'FileUploader',
    props: {
        text: String,
        showProgress: Boolean,
        multiple: Boolean
    },
    emits: ['onUpload'],
    setup(props, context) {
        const selectedFiles = ref();
        const currentFile = ref();
        const progress = ref(0);
        const uploader = ref();
        const showProgressBar = ref(false);
        const MAX_SIZE = process.env.VUE_APP_MAX_SIZE_PHOTO_BYTES;

        async function selectFile() {
            selectedFiles.value = uploader.value.files;

            for (let fileItem of selectedFiles.value) {
                progress.value = 0;
                currentFile.value = fileItem;

                if (fileItem.size > MAX_SIZE) {
                    Swal({
                        title: 'Arquivo muito grande',
                        text: 'O limite máximo para imagens é 50mb',
                        icon: 'warning'
                    });
                    continue;
                }
                
                showProgressBar.value = true;
                UploadService.upload(currentFile.value, event => {
                    progress.value = Math.round((100 * event.loaded) / event.total);
                }).then(response => {
                    context.emit('onUpload', response.data);
                    showProgressBar.value = false;
                }).catch((err) => {
                    progress.value = 0;
                    showProgressBar.value = false;
                    console.log(err)
                    Swal.error({
                        title: 'Erro ao enviar arquivo',
                        text: 'Tente novamente, caso o erro persista verifique se o arquivo está corrompido.',
                    });
                    currentFile.value = null;
                });

                selectedFiles.value = null;
            }
        }

        return { selectedFiles, currentFile, progress, selectFile, uploader, showProgressBar };
    },
}
</script>