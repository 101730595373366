<template>
    <div>
        <PageHeader :title="pageTitle">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
                </svg>
            </template>
        </PageHeader>

        <div class="grid grid-cols-5 mb-6 gap-x-4 card p-12">
            <div>
                <BaseSelect :options="companyOptions" empty-option="Todas" label="Filtrar por cliente" v-model.number="filter.company" />
            </div>
            <div>
                <BaseSelect :options="adminOptions" emptyOption="Todos" label="Colaborador" v-model.number="filter.admin" @change="loadData" />
            </div>
            <div class="flex flex-col">
                <label class="label">Filtrar Data Inicial</label>
                <Datepicker locale="pt-BR" :enableTimePicker="false" format="dd/MM/yyyy" v-model="filter.start_date" autoApply />
            </div>
            <div class="flex flex-col">
                <label class="label">Filtrar Data Final</label>
                <Datepicker locale="pt-BR" :enableTimePicker="false" format="dd/MM/yyyy" v-model="filter.end_date" autoApply />
            </div>
        </div>

        <div class="flex gap-x-8">
            <BaseButton skin="primary" @click="generateReport" :disabled="loading">
                <template v-slot:icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                </template>
                Gerar Relatório
            </BaseButton>
            <div class="flex" v-if="loadingReport">
                <span class="text-green-500 mr-4 mt-3">Gerando...</span>
                <svg class="animate-spin h-5 w-5 text-green-500 mt-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
        </div>
        <div class="flex justify-center mb-4">
            <Loading text="Processando Dados... Aguarde..." :show="loading" />
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';
import PageHeader from '@/components/PageHeader';
import { CompanyService, ReportService, UserAdminService } from '../services/Entity';
import Swal from 'sweetalert';
import { DateTime } from 'luxon';

export default {
    components: {
        PageHeader,
    },
    setup() {
        const loading = ref(false);
        const loadingReport = ref(false);
        const pageTitle = ref('Relatório');
        const companyOptions = ref([]);
        const adminOptions = ref();
        const filter = ref({
            start_date: DateTime.now().minus({ days: 30 }),
            end_date: DateTime.now()
        });

        async function init() {
            loading.value = true;
            await loadData();
            loading.value = false;
        }

        async function loadData() {
            loading.value = true;
            await loadAdmins();
            await loadCompanies();
            loading.value = false;
        }

        async function loadAdmins() {
            try {
                const listingFilter = { page: 1, limit: 99999 };
                const { data: { total, items } } = await UserAdminService.list(listingFilter.page, listingFilter.limit);

                adminOptions.value = items.map(admin => {
                    return {
                        title: admin.name,
                        value: admin.id
                    }
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos colaboradores',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadCompanies(filter) {
            try {
                const listingFilter = { page: 1, limit: 9999 };
                const { data: { total, items } } = await CompanyService.list(listingFilter.page, listingFilter.limit, filter);

                companyOptions.value = items.map(company => {
                    return {
                        title: company.name,
                        value: company.id
                    }
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos clientes',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function generateReport() {
            try {
                loadingReport.value = true;
                const { data } = await ReportService.generateReport(filter.value);
                loadingReport.value = false;

                const link = document.createElement('a');
                
                link.href = data;
                link.setAttribute('download', `relatorio-megaluzz-${Date.now()}.xls`);
                document.body.appendChild(link);
                link.click();
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao gerar o relatório',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        init();

        return {
            filter,
            loading,
            pageTitle,
            loadAdmins,
            adminOptions,
            loadingReport,
            generateReport,
            companyOptions,
        };
    },
}
</script>