<template>
    <div>
        <PageHeader :title="pageTitle">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <BackButton :to="{ name: 'meetings' }" />
        </ActionArea>

        <div class="grid grid-cols-5 mb-6 gap-x-4 card p-12" v-if="!loadingEntireScreen">
            <div class="flex flex-col">
                <label class="label">Filtrar Data Inicial</label>
                <Datepicker locale="pt-BR" :enableTimePicker="false" format="dd/MM/yyyy" v-model="filter.start_date" autoApply @update:modelValue="loadData" />
            </div>
            <div class="flex flex-col">
                <label class="label">Filtrar Data Final</label>
                <Datepicker locale="pt-BR" :enableTimePicker="false" format="dd/MM/yyyy" v-model="filter.end_date" autoApply @update:modelValue="loadData" />
            </div>
        </div>

        <div class="flex justify-center mb-6" v-if="loading">
            <Loading text="Buscando Dados  Aguarde..." :show="loading" />
        </div>
        
        <Alert text="Ainda não existem reuniões realizadas para este cliente" :show="!meetings.length && !loading" />

        <div v-for="meeting in meetings" :key="meeting.id">
            <MeetingCard class="mt-4" :meeting="meeting" />
            <BaseButton v-if="authInfo.has_delete" class="py-2" skin="danger-small" @click="deleteMeeting(meeting.id)">Excluir Reunião</BaseButton>
            <FileTable class="mt-2" v-if="meeting.files && meeting.files.length" :files="meeting.files" :delete-control="false" />
        </div>

    </div>
</template>

<script>

import { ref } from 'vue';
import Alert from '@/components/Alert';
import MeetingCard from '@/components/MeetingCard';
import FileTable from '@/components/FileTable';
import BackButton from '@/components/BackButton';
import { defaultDate } from '../filters/date';
import { MeetingService, CompanyService } from '../services/Entity';
import Swal from 'sweetalert';
import { DateTime } from 'luxon';
import { useRoute } from 'vue-router';
import AuthService from '../services/AuthService';
import LoadingVue from '../components/Loading.vue';

export default {
    name: 'MeetingNotes',
    components: {
        Alert,
        BackButton,
        MeetingCard,
        FileTable
    },
    setup() {
        const pageTitle = ref('Notas de Reunião');
        const page = ref(1);
        const limit = ref(50);
        const route = useRoute();
        const authInfo = AuthService.getAuthInfo();
        const filter = ref({
            start_date: DateTime.now().minus({ days: 30 }),
            end_date: DateTime.now() 
        });
        const totalItens = ref(0);
        const loading = ref(false);
        const loadingEntireScreen = ref(false);
        const meetings = ref([]);
        const company = ref({});

        async function init() {
            loadingEntireScreen.value = true;
            await loadData()
            loadingEntireScreen.value = false;
        }

        async function loadData() {
            let companyId = handleRoute(route);

            if (!companyId) {
                Swal({
                    title: 'Ocorreu um erro ao obter dados das notas',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
                return;
            }

            loading.value = true;            
            filter.value.company = companyId;
            await getCompanyById(companyId)
            await loadMeetings(filter.value);
            
            loading.value = false;
        }

        async function getCompanyById(companyId) {
            try {
                const { data } = await CompanyService.getById(companyId);

                company.value = data;
                pageTitle.value = `Notas de reunião do cliente: ${data.name}`
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados do cliente',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        function handleRoute(route) {
             const routeCompanyId = route.params.company_id;
             return routeCompanyId ? routeCompanyId : false;
        }

        async function loadMeetings(filter) {
            try {
                const listingFilter = { page: 1, limit: 9999 };
                const { data: { total, items }} = await MeetingService.list(listingFilter.page, listingFilter.limit, filter);

                totalItens.value = total;
                meetings.value = items;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos colaboradores',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function deleteMeeting(meetingId) {
            Swal({
                title: 'Tem certeza que deseja excluir essa reunião',
                text: 'Uma vez que excluir, não será possível recuperar',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Excluir Reunião',
                        className: 'swal-button--danger'
                    }
                },
                dangerMode: true
            }).then(async (confirm) => {
                if (confirm) {
                    loading.value = true;
                    const { data: { data } } = await MeetingService.destroy(meetingId);
                    await loadData();

                    Swal({
                        title: `Excluido`,
                        icon: 'success',
                    });
                }
            });
        }

        init();

        return {
            deleteMeeting,
            authInfo,
            defaultDate,
            company,
            loadData,
            pageTitle,
            page,
            limit,
            filter,
            totalItens,
            loading,
            loadingEntireScreen,
            meetings
        }
    }
}
</script>
