
import HttpClient from './HttpService';
import LocalStorage from './LocalStorageService';
import router from "@/router";

function authenticate(username, password) {
    return HttpClient.post('/auth/authenticate', { username, password });
}

function storeLocalData({ token, userAdmin }) {
    LocalStorage.clear();

    const loggedUser = {
        token,
        id: userAdmin.id,
        has_delete: userAdmin.has_delete,
        has_delete_candidate: userAdmin.has_delete_candidate,
        name: userAdmin.name,
        is_admin: userAdmin.is_admin,
        photo_url: userAdmin.photo_url
    };

    LocalStorage.set('authenticated_admin', loggedUser );
}

function getAuthInfo() {
    if (!LocalStorage.get('authenticated_admin')) {
        return false;
    }

    return JSON.parse(LocalStorage.get('authenticated_admin'));
}

function isAuthenticated() {
    if (!LocalStorage.get('authenticated_admin')) {
        return false;
    }

    const { token, id } = JSON.parse(LocalStorage.get('authenticated_admin'));

    return (token && id) ? true : false;
}

function logout() {
    LocalStorage.clear();
    router.push({ name: 'login' });
}

export default {
    logout,
    authenticate,
    storeLocalData,
    getAuthInfo,
    isAuthenticated
}