<template>
	<div class="flex flex-col justify-center mt-12" v-if="show">
		<img src="../assets/loading.gif" class="w-40 ml-4" alt="carregando...">
		<h3 class="text-base text-gray-400 font-semibold mt-2">{{ text }}</h3>
	</div>
</template>

<script>
export default {
	name: 'Loading',
	props: {
		text: String,
		show: Boolean
	}
}
</script>