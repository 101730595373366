<template>
    <div>
        <PageHeader :title="pageTitle">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <BackButton v-if="meeting.company" :to="{ name: 'notes', params: { company_id: meeting.company } }" />
            <BackButton v-if="!meeting.company" :to="{ name: 'meetings' }" />
        </ActionArea>

        <div class="flex justify-center">
            <Loading text="Buscando solicitações... ..." :show="loading" />
        </div>

        <div class="grid grid-cols-1" v-if="!loading">
            <div>
                <form @submit.prevent="submit" novalidate>
                    <BaseFormContainer title="Registrar notas">
                        <template v-slot:form>
                            <div class="grid grid-cols-2 gap-x-8">
                                <div>
                                    <BaseSelect :disabled="meeting.company && isEdit" label="Empresa / Cliente" :options="companyOptions" v-model.number="meeting.company" @change="loadCompanyUsersEmails" />
                                </div>
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="">
                                        <label class="label">Data<BaseRequiredStar /></label>
                                        <Datepicker :enableTimePicker="false" locale="pt-BR" format="dd/MM/yyyy" :clearable="false" v-model="meeting.new_date" autoApply />
                                    </div>
                                    <div>
                                        <BaseInput label="Hora Início" v-maska="MaskService['hour']" v-model="meeting.start_time" />
                                    </div>
                                    <div>
                                        <BaseInput label="Hora Fim" v-maska="MaskService['hour']" v-model="meeting.end_time" />
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4">
                                <BaseTextarea :rows="2" label="Quem foram os participantes dessa reunião?" v-model="meeting.participants" />
                            </div>
                            <div class="mt-4">
                                <BaseSelect :is-invalid="v$.admin.$error" @blur="v$.admin.$touch" :required="true" :options="adminOptions" label="Responsável pelo Andamento" v-model.number="meeting.admin" />
                                <div v-for="error in v$.$errors" :key="error.$uid">
                                    <BaseErrorMessage v-if="error.$property === 'admin'" :message="error.$message" />
                                </div>
                            </div>
                            <div class="mt-4">
                                <label class="label">Insira os emails que vão receber uma cópia das notas públicas. <strong class="text-green-600 font-bold">Separados por vírgula</strong></label>
                                <input type="text" @keydown.enter.prevent v-model="emailTag" @input="addEmail($event)">

                                <span class="text-green-500 text-sm font-semibold">As notas públicas serão enviadas para: ({{ recipients.length }}) email(s)</span>
                                <div class="bg-gray-100 flex gap-x-2 h-auto mt-4 p-1 flex-wrap gap-y-2">
                                    <span v-for="email in recipients" :key="email" class="flex w-auto h-7 px-4 py-1 rounded-lg bg-green-500 text-white font-medium text-sm">{{ email }}
                                        <svg xmlns="http://www.w3.org/2000/svg" @click="removeEmail(email)" class="ml-2 mt-1 h-4 w-4 font-semibold cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 mt-4">
                                <div>
                                    <BaseTextarea :rows="8" label="Observações compartilhadas com o cliente" v-model="meeting.public_obs" />
                                </div>
                            </div>
                            <div class="grid grid-cols-1 mt-4">
                                <div>
                                    <BaseTextarea :rows="8" label="Notas Privadas" v-model="meeting.private_obs" />
                                </div>
                            </div>
                            <FileUploader class="mt-4" text="Adicionar Arquivos" :multiple="true" :showProgress="true" accept="*" @onUpload="onUpload" />
                            <FileTable class="mt-2" :loading="loadingFiles" :files="meeting.files" @removeFile="removeFile" />
                        </template>
                        <template v-slot:actions>
                            <BaseButton skin="primary" :is-submit="true">{{ isEdit ? 'Atualizar': 'Salvar' }}</BaseButton>
                        </template>
                    </BaseFormContainer>
                </form>
            </div>
        </div>

    </div>
</template>

<script>

import { ref, watch, computed } from 'vue';
import { CompanyService, MeetingService, UserAdminService, UserService } from '../services/Entity';
import BackButton from '@/components/BackButton';
import { useRoute, useRouter } from 'vue-router';
import FileUploader from '@/components/FileUploader';
import FileTable from '@/components/FileTable';
import Swal from 'sweetalert';
import { DateTime } from 'luxon';
import { useVuelidate } from '@vuelidate/core'
import MaskService from '../services/MaskService';
import { 
    requiredMessage
} from '../services/VuelidateMessageService';

export default {
    name: 'Meeting',
    components: {
        BackButton,
        FileUploader,
        FileTable,
    },
    setup() {
        const pageTitle = ref('Reunião');
        const meeting = ref({
            files: [],
            new_date: DateTime.now(),
            admin: 1
        });
        const loading = ref(false);
        const loadingFiles = ref(false);
        const companyOptions = ref([]);
        const route = useRoute();
        const router = useRouter();
        const isEdit = ref(true);
        const upload = ref();
        const adminOptions = ref([]);
        const recipients = ref([]);
        const emailTag = ref('');
        const rules = computed(() => ({
            admin: { required: requiredMessage },
            new_date: { required: requiredMessage }
        }));

        function addEmail(e) {
            if (e.data == ',') {
                emailTag.value = emailTag.value.replace(/,/g, '')
                recipients.value.push(emailTag.value);
                emailTag.value = '';
            }
        }

        function removeEmail(email) {
            recipients.value.splice(recipients.value.indexOf(email), 1);
        }

        async function init() {
            loading.value = true;
            await loadData();
            loading.value = false;
        }

        async function loadCompanyUsersEmails() {
            try {
                const companyId = meeting.value.company;
                const listingFilter = { page: 1, limit: 9999 };

                loading.value = true;
                const { data } = await UserService.list(listingFilter.page, listingFilter.limit, { company: companyId });

                const users = data;
                
                recipients.value = [];

                for (let user of users) {
                    if (user && user.email) {
                        recipients.value.push(user.email);
                    }
                }
                loading.value = false;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao listar usuários desta empresa',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadData() {
            const meetingId = handleRoute(route);
            await loadCompanies();
            await loadAdmins();

            if (!meetingId) {
                isEdit.value = false;
                return;
            }
            
            isEdit.value = true;
            await getMeetingById(meetingId);
        }

        function handleRoute(route) {
            const meetingId = route.params.id;

            if (!meetingId) {
                return;
            }

            return meetingId ? meetingId : false;
        }

        function onUpload(data) {
            const { success } = data;
            const file = success[0];

            meeting.value.files.push(file);
        }

        async function loadAdmins() {
            try {
                const listingFilter = { page: 1, limit: 99999 };
                const { data: { total, items } } = await UserAdminService.list(listingFilter.page, listingFilter.limit);

                adminOptions.value = items.map(admin => {
                    return {
                        title: admin.name,
                        value: admin.id
                    }
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos colaboradores',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function removeFile(fileId) {
            try {
                Swal({
                title: 'Deseja realmente remover o arquivo?',
                text: 'Não será possível restaurar o mesmo',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Deletar',
                        className: 'swal-button--danger'
                    }
                }}).then(async (confirm) => {
                    if (!confirm) return;

                    const requestData = { meeting: meeting.value.id, file: fileId };
                    loadingFiles.value = true;
                    meeting.value.files.forEach((file, index) => {
                        if (file.id == fileId) {
                            meeting.value.files.splice(index, 1);
                        }
                    });

                    if (!isEdit.value) {
                        loadingFiles.value = false;
                        return;
                    }

                    await MeetingService.removeFile(requestData.meeting, requestData.file, requestData);
                    loadingFiles.value = false;
                });
            } catch(err) {
                loadingFiles.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao excluir o arquivo',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function submit() {
            const isValid = await v$.value.$validate();
            if (!isValid) return;

            if (!isEdit.value) {
                await save();
            } else {
                await update();
            }
        }

        async function save() {
            meeting.value.recipients = recipients.value;
            const meetingObject = { ...meeting.value };
            
            try {
                loading.value = true;
                const { data } = await MeetingService.create(meetingObject);
                loading.value = false;

                Swal({
                    title: 'Reunião Salva',
                    icon: 'success',
                });

                router.push({ name: 'meeting', params: { id: data.id } });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao salvar a reunião',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function update() {
            const meetingObject = { ...meeting.value };

            try {
                loading.value = true;
                const { data } = await MeetingService.update(meetingObject);
                await getMeetingById(data.id);
                loading.value = false;

                Swal({
                    title: 'Reunião Atualizada!',
                    icon: 'success'
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar a reunião',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function getMeetingById(meetingId) {
            try {
                const { data } = await MeetingService.getById(meetingId);

                meeting.value = data;
                meeting.value.admin = data.admin.id;

                console.log(meeting.value)
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter os dados dessa reunião',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadCompanies() {
            try {
                const filter = { page: 1, limit: 99999 };
                const { data: { total, items } } = await CompanyService.list(filter.page, filter.limit);

                companyOptions.value = items.map(company => {
                    return {
                        title: company.name,
                        value: company.id
                    }
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos clientes',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        watch(() => route.params, async (to, from) => {
            if (route.name == 'meeting' && (from != to)) {
                await init();
            }
        }, { deep: true });

        init();
        const v$ = useVuelidate(rules, meeting.value);

        return {
            v$,
            loadCompanyUsersEmails,
            removeEmail,
            emailTag,
            recipients,
            MaskService,
            addEmail,
            adminOptions,
            removeFile,
            companyOptions,
            pageTitle,
            loading,
            meeting,
            onUpload,
            upload,
            loadingFiles,
            isEdit,
            submit
        };
    }
}
</script>