import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import Profile from '../views/Profile.vue';
import Requests from '../views/Requests.vue';
import AdminUsers from '../views/AdminUsers.vue';
import Jobs from '../views/Jobs';
import Job from '../views/Job';
import Meetings from '../views/Meetings';
import Meeting from '../views/Meeting';
import Team from '../views/Team';
import Reports from '../views/Reports';
import Companies from '../views/Companies';
import Company from '../views/Company';
import Activities from '../views/Activities';
import AuthService from '../services/AuthService';
import User from '../views/User';
import MeetingNotes from '../views/MeetingNotes.vue';
import Candidate from '../views/Candidate.vue';
import Request from '../views/Request.vue';
import MegaFiles from '../views/MegaFiles.vue';
import Tasks from '../views/Tasks.vue';
import Task from '../views/Task.vue';
import TaskForm from '../views/TaskForm.vue';

const routes = [
  {
    path: '/',
    name: 'auth',
    component: Login
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/cadastro-demanda-interna/:id_task?',
    name: 'taskform',
    component: TaskForm
  },
  {
    path: '/demandas-internas',
    name: 'tasks',
    component: Tasks
  },
  {
    path: '/demanda/:id',
    name: 'task',
    component: Task
  },
  {
    path: '/meu-perfil',
    name: 'my-profile',
    component: Profile
  },
  {
    path: '/perfil/:id?',
    name: 'profile',
    component: Profile
  },
  {
    path: '/candidato/:job_id/:id?',
    name: 'candidate',
    component: Candidate
  },
  {
    path: '/usuarios',
    name: 'users',
    component: AdminUsers
  },
  {
    path: '/solicitacoes',
    name: 'requests',
    component: Requests
  },
  {
    path: '/mega-arquivos',
    name: 'megafiles',
    component: MegaFiles
  },
  {
    path: '/solicitacao/:id',
    name: 'request',
    component: Request
  },
  {
    path: '/processos-seletivos',
    name: 'jobs',
    component: Jobs
  },
  {
    path: '/processo-seletivo/:id?',
    name: 'job',
    component: Job
  },
  {
    path: '/reunioes',
    name: 'meetings',
    component: Meetings
  },
  {
    path: '/reuniao/:id?',
    name: 'meeting',
    component: Meeting
  },
  {
    path: '/notes/:company_id?',
    name: 'notes',
    component: MeetingNotes
  },
  {
    path: '/clientes',
    name: 'companies',
    component: Companies
  },
  {
    path: '/usuario/:company_id/:user_id?',
    name: 'user',
    component: User
  },
  {
    path: '/cliente/:id?',
    name: 'company',
    component: Company
  },
  {
    path: '/relatorios',
    name: 'reports',
    component: Reports
  },
  {
    path: '/time',
    name: 'team',
    component: Team
  },
  {
    path: '/atividades',
    name: 'activities',
    component: Activities
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !AuthService.isAuthenticated()) next({ name: 'login' })
  else next();
})

export default router
