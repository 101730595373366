const document = '["###.###.###-##", "##.###.###/####-##"]';
const cnpj = '##.###.###/####-##';
const cpf = '###.###.###-##';
const cep = "#####-###";
const date = '##/##/####';
const numeric = '#*';
const hour = '##:##';
const money = "#*.##";
const phone = '["(##) ####-####", "(##) #####-####"]';

export default {
    document,
    cnpj,
    cpf,
    cep,
    hour,
    date,
    numeric,
    phone,
    money
}