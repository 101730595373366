<template>
    <div>
        <PageHeader :title="pageHeader">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <BackButton :to="companyBackRoute" />
        </ActionArea>

        <div class="flex justify-center mb-6" v-if="loading">
            <Loading text="Buscando Dados  Aguarde..." :show="loading" />
        </div>

        <div class="grid grid-cols-3 gap-x-2" v-if="!loading">
            <div class="col-span-2">
                <form @submit.prevent="submit" novalidate>
                    <BaseFormContainer :title="formTitle">
                        <template v-slot:form>
                                <BaseFormSectionHeader title="Sua foto" subtitle="Escolha uma foto para o seu perfil"></BaseFormSectionHeader>

                                <div class="p-8 flex">
                                    <ThumbRounded class="mr-12" size="large" :path="user.photo_url" />
                                    <FileUploader class="mt-4" text="Enviar Foto" :showProgress="true" :accept="acceptMimeTypeImage" @onUpload="onUpload" />
                                </div> 

                                <BaseFormSectionHeader title="Informações Pessoais" subtitle="Informações Básicas"></BaseFormSectionHeader>
                                <div class="grid grid-cols-3 form-row">
                                    <div>
                                        <BaseInput :is-invalid="v$.name.$error" label="Nome e Sobrenome" @blur="v$.name.$touch" placeholder="Leonardo Da Vinci" v-model="user.name" :required="true" />
                                        <div v-for="error in v$.$errors" :key="error.$uid">
                                            <BaseErrorMessage v-if="error.$property === 'name'" :message="error.$message" />
                                        </div>
                                    </div>
                                    <div>
                                        <BaseInput :is-invalid="v$.email.$error" label="Email" @blur="v$.email.$touch" placeholder="leonardo@davinci.com" v-model="user.email" :required="true" />
                                        <div v-for="error in v$.$errors" :key="error.$uid">
                                            <BaseErrorMessage v-if="error.$property === 'email'" :message="error.$message" />
                                        </div>
                                    </div>
                                    <div>
                                        <BaseInput label="Telefone" placeholder="(11) 99999-9999" v-model="user.phone" v-maska="MaskService['phone']"/>
                                    </div>
                                </div>
                                <div class="grid grid-cols-3">
                                    <div class="flex flex-col justify-start" v-if="user.id">
                                        <label class="label">Tem Acesso ao portal do cliente?</label>
                                        <div class="flex gap-x-4" v-if="user.status">
                                            <span class="text-green-500 font-semibold flex mt-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                                </svg>
                                                Sim
                                            </span>
                                            <BaseButton skin="danger-small" @click="toggleStatus">Revogar Acesso</BaseButton>
                                        </div>
                                        <div class="flex gap-x-4" v-if="!user.status">
                                            <span class="text-red-600 font-semibold flex mt-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                                Não
                                            </span>
                                            <BaseButton skin="primary-small" @click="toggleStatus">Reativar</BaseButton>
                                        </div>
                                    </div>
                                    <div v-if="user.id">
                                        <label class="label">Gerar uma nova senha para este usuário</label>
                                        <BaseButton skin="danger-outline-small" @click="resetPassword">Resetar Senha</BaseButton>
                                    </div>
                                </div>
                        </template>
                        <template v-slot:actions>
                            <BaseButton skin="primary" :is-submit="true">{{ isEdit ? 'Atualizar': 'Salvar' }}</BaseButton>
                        </template>
                    </BaseFormContainer>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import BackButton from '@/components/BackButton';
import { useRoute, useRouter } from 'vue-router';
import MaskService from '../services/MaskService';
import ThumbRounded from '@/components/ThumbRounded';
import FileUploader from '@/components/FileUploader';
import Swal from 'sweetalert';
import { useVuelidate } from '@vuelidate/core';
import { UserService } from '../services/Entity';
import { 
    requiredMessage,
    minLengthMessage,
    emailMessage
} from '../services/VuelidateMessageService';

export default {
    name: 'Profile',
    components: {
        BackButton,
        ThumbRounded,
        FileUploader,
    },
    props: {
        company: [Number, String]
    },
    setup(props) {
        const pageHeader = ref('Gerenciamento de Usuário');
        const formTitle = ref('Adicionar novo usuário');
        const acceptMimeTypeImage = 'image/png,image/gif,image/jpeg,image/webp';
        const route = useRoute();
        const router = useRouter();
        const isEdit = ref(true);
        const loading = ref(false);
        const companyBackRoute = { name: 'company', params: { id: route.params.company_id} };
        const upload = ref();
        const user = ref({
            name: '',
            status: true
        });

        const rules = computed(() => ({
            name: { required: requiredMessage, minLength: minLengthMessage(3) },
            email: { required: requiredMessage, email: emailMessage },
        }));
        
        async function init() {
            loading.value = true;
            await loadData();
            loading.value = false;
        }

        async function loadData() {
            const { companyId, userId } = handleRoute(route);

            if (!userId) {
                isEdit.value = false;
                return;
            }

            isEdit.value = true;
            await getUserById(userId);
            pageHeader.value = `Gerenciamento de Usuário`;
            formTitle.value = `Editar dados de: ${user.value.name}`;
        }

        function handleRoute(route) {
             if (!route.params.company_id) {
                 router.push({ name: 'companies' });
                 return;
             }

             const userId = route.params.user_id;

             return {
                 companyId: route.params.company_id,
                 userId: userId ? userId : false
             };
        }

        async function getUserById(userId) {
            try {
                const { data } = await UserService.getById(userId);

                user.value = data;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados do usuário desta empresa',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        function onUpload(data) {
            const { success } = data;
            const file = success[0];

            user.value.photo = file.id;
            user.value.photo_url = file.url;
        }

        function resetPassword() {
            Swal('Tem certeza que deseja resetar a senha deste usuário?', {
                text: 'Uma vez que resetar a senha, esta pessoa não conseguirá mais entrar com a senha antiga',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Resetar agora',
                        className: 'swal-button--danger'
                    }
                },
                dangerMode: true,
            }).then(async (confirm) => {
                if (confirm) {
                    loading.value = true;
                    const requestData = { id: user.value.id, company: user.value.company };
                    const { data } = await UserService.resetPassword(requestData);
                    loading.value = false;

                    Swal({
                        title: `Nova senha: ${data}`,
                        text: 'Caso o novo usuário não receba esta senha por email, envie a mesma para ele. Esta senha só aparece aqui apenas 1 vez',
                        icon: 'success',
                    });
                }
            });
        }

        async function submit() {
            const isValid = await v$.value.$validate();
            if (!isValid) return;

            if (!isEdit.value) {
                await save();
            } else {
                await update();
            }
        }

        async function save() {
            const userObject = { ...user.value, company: route.params.company_id };
            
            try {
                loading.value = true;
                const { data } = await UserService.create(userObject);
                loading.value = false;

                Swal({
                    title: `Senha: ${data.plainTexPassword}`,
                    text: 'Caso o novo usuário não receba esta senha por email, envie a mesma para ele. Esta senha só aparece aqui apenas 1 vez',
                    icon: 'success',
                    buttons: {
                        confirm: {
                            text: 'Ok!',
                            className: 'swal-button--success'
                        }
                    },
                }).then(async (confirm) => {
                    router.push({ name: 'user', params: { company_id: data.company, user_id: data.id } });
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao salvar o cliente',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function update() {
            const userObject = { ...user.value };
            
            try {
                loading.value = true;
                const { data } = await UserService.update(userObject);
                await getUserById(data.id);
                loading.value = false;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        function toggleStatus() {
            const statusToChange = !user.value.status;
            const title = statusToChange ? 'Tem certeza que deseja reativar o acesso deste usuário?': 'Tem certeza que deseja bloquear o acesso deste usuário?';
            const text = statusToChange ? 'Uma vez que reativar, essa pessoa conseguirá fazer login novamente' : 'Uma vez que bloquear, esta pessoa será deslogado e não conseguirá mais entrar';
            const buttonClass = statusToChange ? 'swal-button--success' :  'swal-button--danger';
            const textButton = statusToChange ? 'Reativar' : 'Bloquear Agora';

            Swal({
                title,
                text,
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: textButton,
                        className: buttonClass
                    }
                }
            }).then(async (confirm) => {
                if (confirm) {
                    loading.value = true;
                    const { data } = await UserService.toggleStatus(user.value.id);
                    await getUserById(data.id);
                    loading.value = false;
                    Swal({
                        title: 'Feito!',
                        icon: 'success'
                    });
                }
            });
        }

        watch(() => route.params, async (to, from) => {
            if (route.name == 'user' && (from != to)) {
                await init();
            }
        }, { deep: true });

        init();
        const v$ = useVuelidate(rules, user);

        return {
            pageHeader,
            formTitle,
            toggleStatus,
            user,
            MaskService,
            isEdit,
            resetPassword,
            companyBackRoute,
            acceptMimeTypeImage,
            upload,
            onUpload,
            v$,
            submit,
            save,
            update,
            loading
        };
    }
}
</script>

