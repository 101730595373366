
import HttpClient from '../HttpService';

function getById(requestId) {
    return HttpClient.get(`/meetings/${requestId}`);
}

function create(data) {
    return HttpClient.post(`/meetings`, data);
}

function list(page, limit, data) {
    return HttpClient.post(`/meetings/list?page=${page}&limit=${limit}`, data);
}

function update(data) {
    return HttpClient.put(`/meetings/${data.id}`, data);
}

function addFile(data) {
    return HttpClient.put(`/meetings/${data.meeting}/files/${data.file}`, data);
}

function removeFile(meetingId, fileId, data) {
    return HttpClient.delete(`/meetings/${meetingId}/files/${fileId}`, data);
}

function destroy(meetingId) {
    return HttpClient.delete(`/meetings/${meetingId}`);
}

export default {
    destroy,
    list,
    create,
    update,
    getById,
    addFile,
    removeFile
}
