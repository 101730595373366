<template>
    <div>
        <PageHeader :title="pageTitle">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                </svg>
            </template>
        </PageHeader>

        <ActionArea>
            <BackButton :to="{ name: 'tasks' }" />
        </ActionArea>

        <div class="flex justify-center">
            <Loading text="Buscando demandas... ..." :show="loading" />
        </div>

        <div v-if="!loading">
            <form @submit.prevent="submit" novalidate>
                <BaseFormContainer title="Detalhes da demanda">
                    <template v-slot:form>
                        <div class="grid grid-cols-1">
                            <div class="mt-4">
                                <BaseSelect is-invalid="v$.admin.$error" empty-option="Escolha um responsável" @blur="v$.admin.$touch" :options="adminOptions" label="Responsável" v-model="task.admin" />
                                <div v-for="error in v$.$errors" :key="error.$uid">
                                    <BaseErrorMessage v-if="error.$property === 'admin'" :message="error.$message" />
                                </div>
                            </div>
                            <div class="mt-4">
                                <div>
                                    <BaseTextarea is-invalid="v$.description.$error" @blur="v$.description.$touch" rows="7" label="Descrição da demanda" v-model="task.description" :maxlength="20000" />
                                </div>
                                <div v-for="error in v$.$errors" :key="error.$uid">
                                    <BaseErrorMessage v-if="error.$property === 'description'" :message="error.$message" />
                                </div>
                            </div>
                        </div>
                        <FileUploader class="mt-4" text="Adicionar Arquivos" :multiple="true" :showProgress="true" accept="*" @onUpload="onUpload" />
                        <FileTable class="mt-2" :loading="loadingFiles" :files="task.files" @removeFile="removeRequestFile" />
                    </template>
                    <template v-slot:actions>
                        <BaseButton skin="primary" :is-submit="true">{{ isEdit ? 'Atualizar': 'Salvar' }}</BaseButton>
                    </template>
                </BaseFormContainer>
            </form>
        </div>

    </div>
</template>

<script>

import { ref, computed, watch } from 'vue';
import { TaskService, UserAdminService } from '../services/Entity';
import Swal from 'sweetalert';
import { useRoute, useRouter } from 'vue-router';
import FileTable from '@/components/FileTable';
import FileUploader from '@/components/FileUploader';
import { useVuelidate } from '@vuelidate/core';
import { requiredMessage } from '../services/VuelidateMessageService';
import BackButton from '../components/BackButton.vue';

export default {
    components: {
        FileTable,
        BackButton,
        FileUploader,
    },
    setup() {
        const pageTitle = ref('Cadastro de Demanda');
        const loadingFiles = ref(false);
        const isEdit = ref(false);
        const loading = ref(false);
        const route = useRoute();
        const router = useRouter();
        const adminOptions = ref();
        const task = ref({
            files: [],
        });
        const rules = computed(() => ({
            description: { required: requiredMessage },
            admin: { required: requiredMessage },
        }));

        async function init() {
            loading.value = true;
            await loadData()
            loading.value = false;
        }

        async function loadData() {
            const { taskId } = handleRoute(route);

            await loadAdmins();

            if (taskId) {
                isEdit.value = true;
                await getTaskById(taskId);
            }
        }

        function handleRoute(route) {

            return {
                taskId: route.params.id_task
            };
        }

        async function loadAdmins() {
            try {
                const listingFilter = { page: 1, limit: 99999 };
                const { data: { total, items } } = await UserAdminService.list(listingFilter.page, listingFilter.limit);

                adminOptions.value = items.map(admin => {
                    return {
                        title: admin.name,
                        value: admin.id
                    }
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos colaboradores',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function getTaskById(taskId) {
            try {
                const { data } = await TaskService.getById(taskId);
                task.value = data;
                task.value.admin = data.admin.id;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados das demandas',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function save() {
            try {
                const taskObject  = { ...task.value };
                loading.value = true;
                const { data } = await TaskService.create({ files: taskObject.files, type: taskObject.type, description: taskObject.description, admin: taskObject.admin });

                Swal({
                    title: 'Demanda Salva!',
                    icon: 'success'
                });
                
                router.push({ name: 'taskform', params: { id_task: data.id } });

            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao salvar o status da demanda',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function update() {
            try {
                const taskObject  = { ...task.value };
                loading.value = true;
                const { data } = await TaskService.update({ files: taskObject.files, id: taskObject.id, status: taskObject.status, description: taskObject.description, admin: taskObject.admin });

                Swal({
                    title: 'Atualizado!',
                    icon: 'success'
                });

                task.value = data;
                task.value.admin = data.admin.id;
                loading.value = false;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao atualizar o status da demanda',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function submit() {
            if (!isEdit.value) {
                await save();
            } else {
                await update();
            }
        }

        function onUpload(data) {
            const { success } = data;
            const file = success[0];

            task.value.files.push(file);
        }

        async function removeRequestFile(fileId) {
            try {
                Swal({
                title: 'Deseja realmente remover o arquivo?',
                text: 'Não será possível restaurar o mesmo',
                icon: 'warning',
                buttons: {
                    cancel: 'Cancelar',
                    confirm: {
                        text: 'Deletar',
                        className: 'swal-button--danger'
                    }
                }}).then(async (confirm) => {
                    if (!confirm) return;

                    const requestData = { task: task.value.id, file: fileId };
                    loadingFiles.value = true;
                    task.value.files.forEach((file, index) => {
                        if (file.id == fileId) {
                            task.value.files.splice(index, 1);
                        }
                    });

                    if (!task.value.id) {
                        loadingFiles.value = false;
                        return;
                    }

                    await TaskService.removeFile(requestData.task, requestData.file, requestData);
                    loadingFiles.value = false;
                });
            } catch(err) {
                loadingFiles.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao excluir o arquivo',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        watch(() => route.params, async (to, from) => {
            if (route.name == 'taskform' && (from != to)) {
                await init();
            }
        }, { deep: true });

        init();
        const v$ = useVuelidate(rules, task);

        return {
            isEdit,
            removeRequestFile,
            submit,
            rules,
            v$,
            adminOptions,
            loadingFiles,
            onUpload,
            update,
            getTaskById,
            loadData,
            pageTitle,
            task,
            loading
        };
    },
}
</script>