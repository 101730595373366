<template>
    <div>
        <div class="flex flex-col">
            <div class="flex gap-x-4">
                <div class="h-20 w-20 bg-green-500 flex justify-center text-4xl items-center font-extrabold text-white">
                    {{ dateInfo.day }}
                </div>
                <span class="text-green-500 text-3xl font-bold mt-5">{{ dateInfo.month }}/ {{ dateInfo.year }}</span>
                <span class="text-gray-400 text-2xl font-bold mt-6">{{ dateInfo.hour }} : {{ dateInfo.minutes }}</span>
            </div>
            <div class="ml-24 -mt-6">
                <span class="text-gray-400 text-sm font-bold mt-6">Ultima vez atualizado: {{ dateTime(tasknote.updated_at) }}</span>
            </div>
        </div>
        <div class="card mt-4 p-10">
            <BaseButton skin="primary" v-if="(tasknote.admin && (loggedUserId === tasknote.admin.id))" @click="editNote">Editar Comentário</BaseButton>
            <div class="grid grid-cols-1">
                <span class="text-green-500 text-2xl font-bold mt-5">{{ tasknote.admin.name }}</span>
            </div>
            <div class="grid grid-cols-1 mt-10">
                <div>
                    <label class="label">Comentário</label>
                    <p class="text-gray-700 text-md font-medium text-justify">{{ tasknote.description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { computed } from 'vue';
import { DateTime } from 'luxon';
import { dateTime } from '../filters/date';

export default {
    emits: ['edit-task-note'],
    props: {
        tasknote: Object,
        loggedUserId: [String, Number]
    },
    setup(props, context) {
        const dateInfo = computed(() => {
            const extractedDate = DateTime.fromISO(props.tasknote.created_at);

            return {
                day: extractedDate.day,
                month: extractedDate.toFormat('LLLL'),
                year: extractedDate.year,
                hour: extractedDate.hour,
                minutes: extractedDate.toFormat('mm')
            }
        });

        function editNote() {
            context.emit('edit-task-note', props.tasknote);
        }

        return { 
            editNote,
            dateInfo,
            dateTime
        }
    }
}
</script>
