<template>
    <div>
        <PageHeader :title="pageHeader">
            <template v-slot:icon>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </template>
        </PageHeader>

        <div class="grid grid-cols-5 mb-6 gap-x-4 card p-12" v-if="!loadingEntireScreen">
            <div>
                <BaseSelect :options="companyOptions" empty-option="Todos" label="Filtrar por cliente" v-model.number="filter.company" @change="loadData" />
            </div>
            <div class="flex flex-col">
                <label class="label">Filtrar Data Inicial</label>
                <Datepicker locale="pt-BR" :enableTimePicker="false" format="dd/MM/yyyy" v-model="filter.start_date" autoApply @update:modelValue="loadData" />
            </div>
            <div class="flex flex-col">
                <label class="label">Filtrar Data Final</label>
                <Datepicker locale="pt-BR" :enableTimePicker="false" format="dd/MM/yyyy" v-model="filter.end_date" autoApply @update:modelValue="loadData" />
            </div>
        </div>

        <div class="flex justify-center pb-4">
            <Loading :show="loading" text="Carregando dados, aguarde..." />
        </div>

        <div class="grid grid-cols-3 gap-x-4" v-if="!loadingEntireScreen">
            <div>
                <router-link :to="{ name: 'companies'}">
                    <ResumeCard label="Clientes" :value="analyticsValue.companies">
                        <template v-slot:icon>
                            <div class="bg-blue-100 p-1 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                                </svg>
                            </div>
                        </template>
                    </ResumeCard>
                </router-link>
            </div>
            <div>
                <router-link :to="{ name: 'meetings'}">
                    <ResumeCard label="Reuniões Realizadas" :value="analyticsValue.meetings">
                        <template v-slot:icon>
                            <div class="bg-blue-100 p-1 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                </svg>
                            </div>
                        </template>
                    </ResumeCard>
                </router-link>
            </div>
            <div>
                <router-link :to="{ name: 'jobs'}">
                    <ResumeCard label="Processos Seletivos" :value="analyticsValue.jobs">
                        <template v-slot:icon>
                            <div class="bg-blue-100 p-1 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                                </svg>
                            </div>
                        </template>
                    </ResumeCard>
                </router-link>
            </div>
        </div>

        <div class="grid grid-cols-3 gap-x-4 mt-4" v-if="!loadingEntireScreen">
            <div>
                <router-link :to="{ name: 'tasks'}">
                    <ResumeCard label="Demandas Abertas" :value="analyticsValue.tasks_open">
                        <template v-slot:icon>
                            <div class="bg-red-100 p-1 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                                </svg>
                            </div>
                        </template>
                    </ResumeCard>
                </router-link>
            </div>
            <div>
                <router-link :to="{ name: 'tasks'}">
                    <ResumeCard label="Demandas em Progresso" :value="analyticsValue.tasks_in_progress">
                        <template v-slot:icon>
                            <div class="bg-yellow-100 p-1 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                                </svg>
                            </div>
                        </template>
                    </ResumeCard>
                </router-link>
            </div>
            <div>
                <router-link :to="{ name: 'tasks'}">
                    <ResumeCard label="Demandas Finalizadas" :value="analyticsValue.tasks_finished">
                        <template v-slot:icon>
                            <div class="bg-green-100 p-1 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                                </svg>
                            </div>
                        </template>
                    </ResumeCard>
                </router-link>
            </div>
        </div>

        <div class="grid grid-cols-3 gap-x-4 mt-4" v-if="!loadingEntireScreen">
            <div>
                <router-link :to="{ name: 'requests'}">
                    <ResumeCard label="Pedidos Abertos" :value="analyticsValue.requests_open">
                        <template v-slot:icon>
                            <div class="bg-blue-100 p-1 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                                </svg>
                            </div>
                        </template>
                    </ResumeCard>
                </router-link>
            </div>
            <div>
                <router-link :to="{ name: 'requests'}">
                    <ResumeCard label="Pedidos em Progresso" :value="analyticsValue.requests_in_progress">
                        <template v-slot:icon>
                            <div class="bg-blue-100 p-1 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                                </svg>
                            </div>
                        </template>
                    </ResumeCard>
                </router-link>
            </div>
            <div>
                <router-link :to="{ name: 'requests'}">
                    <ResumeCard label="Pedidos Finalizados" :value="analyticsValue.requests_finished">
                        <template v-slot:icon>
                            <div class="bg-blue-100 p-1 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                                </svg>
                            </div>
                        </template>
                    </ResumeCard>
                </router-link>
            </div>
        </div>

        <div class="card p-8 mt-4" v-if="!loadingEntireScreen">
            <BarChart v-bind="barChartProps" />
        </div>
    </div>
</template>

<script>

import { ref, computed, toRefs, reactive } from 'vue';
import ResumeCard from '@/components/ResumeCard';
import { CompanyService, DataAnalyticsService } from '../services/Entity';
import Swal from 'sweetalert';
import { Chart, registerables } from 'chart.js';
import { BarChart, useBarChart } from 'vue-chart-3';
import UtilService from '../services/UtilService';
import { DateTime } from 'luxon';

Chart.register(...registerables);

export default {
    components: {
        ResumeCard,
        BarChart
    },
    setup() {
        const loading = ref(false);
        const loadingEntireScreen = ref(false);
        const pageHeader = ref('Dashboard');
        const analyticsValue = ref({});
        const companyOptions = ref([]);
        const filter = ref({
            start_date: DateTime.now().minus({ days: 90 }),
            end_date: DateTime.now() 
        });
        const barProperties = reactive({
            barChartProps: null,
            barChartRef: null
        });

        async function init() {
            loadingEntireScreen.value = true;
            await loadData();
            loadingEntireScreen.value = false;
        }

        async function loadData() {
            loading.value = true;
            await loadCountAnalytics(filter.value);
            await loadDataBarChart(filter.value);
            await loadCompanies();
            loading.value = false;
        }

        async function loadCountAnalytics(filter) {
            try {
                const { data } = await DataAnalyticsService.countData(filter);
                analyticsValue.value = data;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao contabilizar os dados',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadCompanies() {
            try {
                const filter = { page: 1, limit: 99999 };
                const { data: { total, items } } = await CompanyService.list(filter.page, filter.limit);

                companyOptions.value = items.map(company => {
                    return {
                        title: company.name,
                        value: company.id
                    }
                });
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao obter dados dos clientes',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        async function loadDataBarChart(filter) {
            try {
                let colors = [];
                const { data: { labels, values } } = await DataAnalyticsService.requestsPerAdmin(filter);

                for (let hexColor in values) {
                    let randomColor = UtilService.generateHexColor();
                    colors.push(randomColor);
                }

                const chartData = computed(() => ({
                    labels,
                    datasets: [
                        {
                            label: 'Solicitações',
                            data: values,
                            backgroundColor: colors,
                        },
                    ],
                }));

                const { barChartProps, barChartRef } = useBarChart({ chartData });

                barProperties.barChartProps = barChartProps;
                barProperties.barChartRef = barChartRef;
            } catch(err) {
                loading.value = false;
                console.log(err);
                Swal({
                    title: 'Ocorreu um erro ao contabilizar os dados',
                    text: 'Atualize a página e tente novamente',
                    icon: 'error'
                });
            }
        }

        init();

        return { pageHeader, loading, loadingEntireScreen, analyticsValue, filter, companyOptions, loadData, ...toRefs(barProperties) }
    }
}
</script>
