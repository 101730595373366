<template>
<div>
	<div class="flex">
		<label v-if="label" class="label">
			{{ label }}
			<BaseRequiredStar v-if="$attrs.required" />
		</label>
		<slot name="help"></slot>
	</div>
	<select
		:class="{ 'input-error': isInvalid }"
		:value="modelValue || defaultValue"
		v-bind="{ ...$attrs, onChange: ($event) => { $emit('update:modelValue', $event.target.value) }}">
		<option value="" v-if="!disableEmptyOption">{{ emptyOption ? emptyOption : '-- Selecione --' }}</option>
		<option v-for="option in options" :value="option.value" :key="option.value" :selected="option === modelValue">{{ option.title }}</option>
	</select>
	<slot name="tip"></slot>
</div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: ''
		},
		emptyOption: String,
		defaultValue: {
			type: String
		},
		modelValue: {
			type: [String, Number],
			default: ''
		},
		disableEmptyOption: Boolean,
		options: {
			type: Array,
			required: true
		},
		isInvalid: {
            type: Boolean,
            default: false
        }
	}
}
</script>