
import HttpClient from '../HttpService';

function getById(jobId) {
    return HttpClient.get(`/jobs/${jobId}`);
}

function create(data) {
    return HttpClient.post(`/jobs`, data);
}

function list(page, limit, data) {
    return HttpClient.post(`/jobs/list?page=${page}&limit=${limit}`, data);
}

function update(data) {
    return HttpClient.put(`/jobs/${data.id}`, data);
}

function addFile(data) {
    return HttpClient.put(`/jobs/${data.job}/files/${data.file}`, data);
}

function removeFile(jobId, fileId, data) {
    return HttpClient.delete(`/jobs/${jobId}/files/${fileId}`, data);
}

function destroy(jobId) {
    return HttpClient.delete(`/jobs/${jobId}`);
}

export default {
    destroy,
    list,
    create,
    update,
    getById,
    addFile,
    removeFile
}
