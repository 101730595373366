<template>
    <div class="card p-8 shadow">
        <div class="flex justify-between">
            <h1 class="text-lg text-gray-600 font-bold">{{ label }}</h1>
            <slot name="icon" />
        </div>
        <h2 class="text-4xl font-extrabold text-black text-left">{{ value }}</h2>
        <div class="flex justify-start mt-4 gap-x-2">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
            </svg> -->
            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6" />
            </svg> -->
            <!-- <span class="text-sm font-normal text-green-500">2.5%</span>
            <span class="text-sm font-light text-gray-500">Desde do último mês</span> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'ResumeCard',
    props: {
        label: String,
        value: [String, Number]
    }
}
</script>