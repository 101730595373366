
import HttpClient from '../HttpService';

function listStates(countryId) {
    return HttpClient.get(`/geoinfos/states/${countryId}`);
}

function listCities(stateId) {
    return HttpClient.get(`/geoinfos/cities/${stateId}`);
}

export default {
    listStates,
    listCities
}
