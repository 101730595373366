
import HttpClient from '../HttpService';


function create(data) {
    return HttpClient.post(`/megafiles`, data);
}

function list(page, limit, data) {
    return HttpClient.post(`/megafiles/list?page=${page}&limit=${limit}`, data);
}

function update(data) {
    return HttpClient.put(`/megafiles/${data.id}`, data);
}

function destroy(requestId) {
    return HttpClient.delete(`/megafiles/${requestId}`);
}

export default {
    destroy,
    list,
    create,
    update,
}
