<template>
	<div>
		<button :class="{ 'btn btn-primary': !buttonType, 'btn btn-primary-outline': buttonType === 'outline' }" type="button" @click="toggleModal()">
			<slot name="icon"></slot>
			<span>{{ triggerText }}</span>
		</button>

		<div v-if="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
			<div class="relative mx-auto min-w-1/4 w-auto">
				<!--content-->
				<div class="min-w-200 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
					<!--header-->
					<div class="flex items-start justify-between p-5 border-b border-solid">
						<h3 class="page-title">
							{{ title }}
						</h3>
						<button class="bg-transparent hover:border-gray-200" @click="toggleModal()">
							<span class="text-gray-800 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
						</button>
					</div>
					<!--body-->
					<div class="relative p-6 flex-auto">
						<slot name="body">
							
						</slot>
					</div>
					<!--footer-->
					<div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b gap-x-4">
						<button class="btn btn-primary-outline" type="button" v-on:click="toggleModal()">
							Fechar
						</button>
						<button class="btn btn-primary" type="button" :disabled="isDisabled" @click="doAction()">
							{{ actionText }}
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
	</div>
</template>

<script>
import { ref } from 'vue';

export default {
    name: 'Modal',
	emits: ['modalAction', 'modalStatus'],
	props: {
		triggerText: String,
		buttonType: String,
		title: String,
		actionText: String,
		isDisabled: Boolean
	},
    setup(props, context) {
        let showModal = ref(false);

        function toggleModal() {
            showModal.value = !showModal.value;
			context.emit('modalStatus', showModal.value ? true : false);
        }

		function doAction() {
			context.emit('modalAction');
			toggleModal();
		}

        return { toggleModal, showModal, doAction };
    }
}
</script>